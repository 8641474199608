import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { RightOutlined } from '@ant-design/icons'
import './index.less'
import { useNavigate } from 'react-router-dom'

const QDButton = ({ 
  overlayBackgroundColor = 'red', 
  overlayColor = 'red', 
  backgroundColor = 'transparent', 
  color = 'black', 
  borderColor = 'black',
  text,
  onClick,
  style = {},
  page_uuid = '',
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const divRef = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    const el = divRef.current

    const handleMouseOver = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    el?.addEventListener('mouseover', handleMouseOver)
    el?.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      el?.removeEventListener('mouseover', handleMouseOver)
      el?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  return (
    <div ref={divRef} className="qd-button-container" style={{
      ...style,
      backgroundColor, 
      color: isHovered ? overlayColor : color
    }} onClick={() => {
      page_uuid && navigate(`/page/${page_uuid}`)
      onClick && onClick()
    }}>
      
      <div className="qd-button-body-container font-weight-bold-zh font-weight-black-en">
        {text}
        <RightOutlined style={{ marginLeft: 18 }} />
      </div>

      <div className={classnames({
        "qd-button-border-container": true,
        "qd-button-border-container__active": isHovered,
        "qd-button-border-container__leave": !isHovered
    })} style={{ 
        border: `2px solid ${borderColor}`,
      }}

      />

      <div className={classnames({
        "qd-button-animation-container": true,
        "qd-button-animation-container__active": isHovered,
        "qd-button-animation-container__leave": !isHovered
      })} style={{ 
        border: `2px solid ${overlayBackgroundColor}`,
        backgroundColor: overlayBackgroundColor,
      }} />
    </div>
  )
}

export const EmbededQDButton = ({ 
  overlayBackgroundColor = 'red', 
  overlayColor = 'red', 
  backgroundColor = 'transparent', 
  color = 'black', 
  borderColor = 'black',
  text,
  onClick,
  style = {},
  href = '',
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const divRef = useRef()

  useEffect(() => {
    const el = divRef.current

    const handleMouseOver = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    el?.addEventListener('mouseover', handleMouseOver)
    el?.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      el?.removeEventListener('mouseover', handleMouseOver)
      el?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  return (
    <div ref={divRef} className="qd-button-container" style={{
      ...style,
      backgroundColor, 
      color: isHovered ? overlayColor : color
    }} onClick={() => {
      if (href) {
        window.location.href = href
      }
      onClick && onClick()
    }}>
      
      <div className="qd-button-body-container">
        {text}
        <RightOutlined style={{ marginLeft: 18 }} />
      </div>

      <div className={classnames({
        "qd-button-border-container": true,
        "qd-button-border-container__active": isHovered,
        "qd-button-border-container__leave": !isHovered
      })} style={{ 
        border: `2px solid ${borderColor}`,
      }}

      />

      <div className={classnames({
        "qd-button-animation-container": true,
        "qd-button-animation-container__active": isHovered,
        "qd-button-animation-container__leave": !isHovered
      })} style={{ 
        border: `2px solid ${overlayBackgroundColor}`,
        backgroundColor: overlayBackgroundColor,
      }} />
    </div>
  )
}

export default QDButton