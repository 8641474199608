import { Dropdown } from "antd"
import { GlobalOutlined } from "@ant-design/icons"
import './index.less'
import classNames from "classnames"
import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { setWebsiteResource } from "../../../../store/slice/websiteSlice"
import { FlexContainer } from "../../../FlexComps"

const LANGUAGES = [
  {
    'key': 'zh',
    'zh': '简体中文',
    'en': '简体中文',
  },
  {
    'key': 'en',
    'zh': 'ENGLISH',
    'en': 'ENGLISH',
  },
]

const GLOBAL_SCHOOLS = [
  {
    'key': 'global_network',
    'zh': '全球网络',
    'en': 'GLOBAL NETWORK',
    'url': 'https://www.dwight.edu/'
  },
  {
    'key': 'new_york',
    'zh': '纽约校区',
    'en': 'NEW YORK',
    'url': 'https://www.dwight.edu/'
  },
  {
    'key': 'london',
    'zh': '伦敦校区',
    'en': 'LONDON',
    'url': 'https://www.dwightlondon.org/'
  },
  {
    'key': 'seoul',
    'zh': '首尔校区',
    'en': 'SEOUL',
    'url': 'https://www.dwight.or.kr/'
  },
  {
    'key': 'shanghai',
    'zh': '上海校区',
    'en': 'SHANGHAI',
    'url': 'https://www.qibaodwight.org/'
  },
  {
    'key': 'dubai',
    'zh': '迪拜校区',
    'en': 'DUBAI',
    'url': 'https://www.dwight.ae/'
  },
]

const SchoolSwitcher = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const locale = useSelector(state => state.website?.locale)

  const schoolList = useMemo(() => GLOBAL_SCHOOLS.map(school => ({
    label: (
      <span className={classNames({
        "font-weight-medium-zh": school.key !== 'shanghai',
        "font-weight-bold-zh": school.key === 'shanghai',        
        "font-weight-medium-en": school.key !== 'shanghai',
        "font-weight-bold-en": school.key === 'shanghai',   
        "school-label-span": true,
        "school-label-span__active": school.key === 'shanghai'
      })}>{school[locale]}</span>
    ),
    key: school.key
  })), [locale])

  const langList = useMemo(() => LANGUAGES.map(lang => ({
    label: (
      <span className={classNames({
        "font-weight-medium-zh": lang.key !== locale,
        "font-weight-bold-zh": lang.key === locale,
        "font-weight-medium-en": lang.key !== locale,
        "font-weight-bold-en": lang.key === locale,
        "lang-label-span": true,
        "lang-label-span__active": lang.key === locale,
        
      })}>{lang[locale]}</span>
    ),
    key: lang.key
  })), [locale])

  return (
    <div className="school-switcher-wrapper">
      <Dropdown         
        className="lang-switcher-wrapper__dropdown" 
        placement="bottomLeft"
        arrow={{ pointAtCenter: true }} 
        menu={{ items: langList, onClick: ({ key }) => {
          const newLocale = locale === 'zh' ? 'en' : 'zh'
          dispatch(setWebsiteResource({ locale: newLocale }))
          localStorage.setItem("__locale__", newLocale)
          navigate('/')
        }}}
        dropdownRender={menu => (
          <div className="dropdown-content">
            {menu}
          </div>
        )}
      >
        <div className="lang-switcher-wrapper__icon">
          {locale === 'zh' ? 'EN' : '中'}
        </div>
      </Dropdown>
      <Dropdown 
        className="school-switcher-wrapper__dropdown" 
        placement="bottom"
        overlayClassName="school-switcher-wrapper__dropdown_container"
        arrow={{ pointAtCenter: true }} 
        menu={{ items: schoolList, onClick: ({ key }) => {
          if (key !== 'shanghai') {
            window.open(GLOBAL_SCHOOLS.find(school => school.key === key).url)
          }
        } }}
        dropdownRender={menu => (
          <div className="dropdown-content">
            {menu}
          </div>
        )}
      >
        <FlexContainer className="font-weight-bold-zh font-weight-bold-en" alignItems="center" style={{ cursor: 'pointer', fontSize: 15, height: '100%', whiteSpace: 'nowrap' }}>{locale === 'zh' ? '上海校区' : 'SHANGHAI'}</FlexContainer>
      </Dropdown>
    </div>
  )
}

export default SchoolSwitcher