import './index.less'

export const ContainerWithGradientBackground = ({ children }) => {
  return (
    <div className="gradient-background-container">{children}</div>
  )
}

export const ContainerWithBackgroundImage = ({ imgUrl, children, type = "mask" }) => {
  return (
    <div className="background-image-container">
      <img src={imgUrl} alt="" className="background-image-container__image" />
      {type === 'mask' && <div className="background-image-container__mask" />}
      {type === 'shadow' && <div className="background-image-container__shadow" />}

      <div className="background-image-container__body">
        {children}
      </div>
    </div>
  )
}