import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EventBlock, FactBlock, VideoBlock, WelcomeBlock } from '../../components/QDBlock';
import QDCarousel from '../../components/QDCarousel';
import SectionDivider from '../../components/SectionDivider';

function Home() {
  const locale = useSelector(state => state.website?.locale)

  useEffect(() => {
    document.title = locale === 'zh' ? '首页 - 七宝德怀特' : 'Home - Qibao Dwight'
  }, [locale])

  return (
    <>
      <QDCarousel />
      <SectionDivider />
      <WelcomeBlock />
      <SectionDivider color="white" />
      <FactBlock />
      <SectionDivider />
      <EventBlock />
      <VideoBlock />
    </>
  );
}

export default Home