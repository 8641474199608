import { Button, Input, Drawer, Collapse, Divider, Empty, Dropdown } from "antd"
import { SearchOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState, useRef } from "react"
import { FlexContainer } from '../../../FlexComps'
import './index.less'
import classNames from "classnames"
import { useDispatch, useSelector } from 'react-redux'
import { mobileCheck } from "../../../../utils"
import { Link, useNavigate } from "react-router-dom"
import SchoolSwitcher from "../SchoolSwitcher"
import ButtonGroup from "antd/lib/button/button-group"

// const MY_QD_LIST = [
//   {
//     'key': 'zh',
//     'zh': '教师 SHAREPOINT',
//     'en': 'STAFF SHAREPOINT',
//     'url': 'https://qdhs.sharepoint.cn/sites/QDstaff/'
//   },
//   {
//     'key': 'en',
//     'zh': '学生 SHAREPOINT',
//     'en': 'STUDENT SHAREPOINT',
//     'url': 'https://qdhs.sharepoint.cn/sites/CommunicationSite-Students'
//   },
// ]

const Sidebar = ({ visible, setVisible }) => {
  const navigate = useNavigate()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [activeKey, setActiveKey] = useState([])
  const websiteRoutes = useSelector(state => state.website?.config?.website_routes || [])
  const locale = useSelector(state => state.website?.locale)

  const searchInputRef = useRef(null)

  const activeMenus = useMemo(() => {
    if (searchKeyword) {
      return websiteRoutes.filter(menu => menu.children.some(page => page.title.toLowerCase().includes(searchKeyword.toLowerCase()))).map(menu => menu.key)
    } else {
      return []
    }
  }, [searchKeyword, websiteRoutes])

  useEffect(() => {
    setActiveKey(activeMenus)
  }, [activeMenus])

  useEffect(() => {
    setTimeout(() => {
      searchInputRef.current?.focus()
    }, 32)
  }, [visible])

  return (
    <Drawer className="sidebar-drawer-wrapper" bodyStyle={{ background: '#f8f8f8', padding: mobileCheck() ? '36px 30px' : '30px 55px' }} width={mobileCheck() ? '100%' : 486} placement="right" onClose={() => setVisible(false)} open={visible}>
      {mobileCheck() && (
        <FlexContainer justifyContent="space-between" className="sidebar-drawer-wrapper__mobile-toolbox">
          <SchoolSwitcher />
          <ButtonGroup>
            <Button className="sidebar-navbar-wrapper__btn font-weight-bold-zh font-weight-bold-en" onClick={() => {
              if (locale === 'zh') {
                navigate('/page/99f0ee81-d75f-4d78-b2d8-f109f4176308?fromMenuKey=2')
              } else {
                navigate('/page/99f0ee81-d75f-4d78-b2d8-f109f4176408?fromMenuKey=1002')
              }
              setVisible(false)
            }}>
              {locale === 'zh' ? '访校预约' : 'VISIT'}
            </Button>

            <Button className="sidebar-navbar-wrapper__btn font-weight-bold-zh font-weight-bold-en" onClick={() => window.open('https://admissions.qibaodwight.org/')}>
              {locale === 'zh' ? '招生平台' : 'APPLY'}
            </Button>
          </ButtonGroup>
        </FlexContainer>
      )}
      
      <FlexContainer style={{ height: 60, marginBottom: mobileCheck() ? 6 : 55 }}>
        <Input ref={searchInputRef} className="sidebar-drawer-wrapper__search-input" placeholder={locale === 'zh' ? "搜索..." : "Have a look..."} suffix={<span className="sidebar-search-icon" />} onChange={e => setSearchKeyword(e.target.value)} />
        <div className="sidebar-close-icon" onClick={() => setVisible(false)} />
      </FlexContainer>

      {Boolean(searchKeyword && activeMenus.length === 0) && <Empty style={{ marginTop: 24 }} />}

      <Collapse activeKey={activeKey} accordion={!searchKeyword} bordered={false} expandIconPosition="end" onChange={v => setActiveKey(Array.isArray(v) ? v : [v])} style={{ background: 'transparent' }}>
        {
          websiteRoutes.filter(menu => !searchKeyword || activeMenus.includes(menu.key)).map(nav => (
            <Collapse.Panel showArrow={false} extra={<span className={activeKey.includes(nav.key) ? "sidebar-collapse-panel-collapse-icon" : "sidebar-collapse-panel-expand-icon"} />} key={nav.key} header={
              <div className={classNames({
                "font-weight-bold-zh": true,
                "font-weight-black-en": true,
                "collapse-panel-header": true,
                "collapse-panel-header__active": activeKey.includes(nav.key)
              })} style={{ textTransform: 'uppercase' }}>{nav.title}</div>
            }>
              <FlexContainer flexDirection="column" gap="18px">
                {
                  nav.children?.filter(item => !searchKeyword || item.title.toLowerCase().includes(searchKeyword.toLowerCase()))?.map(page => (
                    <div key={page.key} className="collapse-panel-page-item font-weight-medium-zh font-weight-medium-en ">
                      <Link to={`/page/${page.key}?fromMenuKey=${nav.key}`} onClick={() => setVisible(false)}>{page.title}</Link>
                    </div>
                  ))
                }
              </FlexContainer>
            </Collapse.Panel>
          ))
        }
      </Collapse>
    </Drawer>
  )
}

const Navbar = () => {
  const navigate = useNavigate()

  const locale = useSelector(state => state.website.locale)
  const [visible, setVisible] = useState(false)

  const { popularLinks } = useSelector(state => ({
    popularLinks: state.website?.config?.footer_block_popular_links || []
  }))

  useEffect(() => {
    let timeout

    const handleMouseEnter = () => {
      timeout = setTimeout(() => {
        setVisible(true)
        timeout = undefined
      }, 500)
    }

    const handleMouseLeave = () => {
      timeout && clearTimeout(timeout)
    }


    document.querySelector(".navbar-wrapper__sidebar-trigger")?.addEventListener('mouseenter', handleMouseEnter)
    document.querySelector(".navbar-wrapper__sidebar-trigger")?.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      document.querySelector(".navbar-wrapper__sidebar-trigger")?.removeEventListener('mouseenter', handleMouseEnter)
      document.querySelector(".navbar-wrapper__sidebar-trigger")?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  const myQdList = useMemo(() => popularLinks.map(item => ({
    label: (
      <span className={classNames({
        "my-qd-label-span": true,
      })}>{item.label}</span>
    ),
    key: item.label
  })), [])

  return (
    <div className="navbar-wrapper">
      {
        !mobileCheck() && (
          <>
            <Button className="navbar-wrapper__btn font-weight-bold-zh font-weight-bold-en" onClick={() => {
              if (locale === 'zh') {
                navigate('/page/99f0ee81-d75f-4d78-b2d8-f109f4176308?fromMenuKey=2')
              } else {
                navigate('/page/99f0ee81-d75f-4d78-b2d8-f109f4176408?fromMenuKey=1002')
              }
            }}>
              {locale === 'zh' ? '访校预约' : 'VISIT'}
            </Button>

            <Button className="navbar-wrapper__btn font-weight-bold-zh font-weight-bold-en" onClick={() => window.open('https://admissions.qibaodwight.org/')}>
              {locale === 'zh' ? '招生平台' : 'APPLY'}
            </Button>

            <Dropdown         
              className="navbar-wrapper__btn" 
              placement="bottomLeft"
              arrow={{ pointAtCenter: true }} 
              menu={{ items: myQdList, onClick: ({ key }) => {
                const myQd = popularLinks.find(item => item.label === key)
                window.open(myQd.redirect_url)
              }}}
              dropdownRender={menu => (
                <div className="dropdown-content">
                  {menu}
                </div>
              )}
            >
              <Button className="font-weight-bold-zh font-weight-bold-en">
                MYQD
              </Button>            
            </Dropdown>
          </>
        )
      }

      <Button className="navbar-wrapper__sidebar-trigger" style={{ padding: '0 14px'}} onClick={() => setVisible(true)}>
        <span>
          <Divider type="vertical" style={{ height: 30, margin: '0 12px', borderColor: 'hsla(0,0%,100%,.4)' }} />
        </span>
      </Button>
      <Sidebar visible={visible} setVisible={setVisible} />
    </div>
  )
}

export default Navbar