import { Col, Pagination, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NewsCard from '../../components/NewsCard'
import { CarouselIntro } from '../../components/QDCarousel'
import NewsEvent from '../NewsEvent'
import './index.less'
import { mobileCheck } from '../../utils'

const PAGE_SIZE = 12

const News = () => {
  const locale = useSelector(state => state.website?.locale)
  const newsList = useSelector(state => state.website?.newsList || [])
  const navigate = useNavigate()

  const [pageNum, setPageNum] = useState(1)

  // const [newsList, setNewsList] = useState([])
  // const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.title = locale === 'zh' ? `新闻中心 - 七宝德怀特` :  `News Center - Qibao Dwight` 
  }, [locale])
 
  return (
    <div classNames="news-container">
      <div className="news-container__cover-image">
        <img src="https://tais.obs.cn-east-3.myhuaweicloud.com/public/upload/2022-09/29/%E6%AF%95%E4%B8%9A2022.jpg" alt="page cover image" />
        <CarouselIntro text={locale === 'zh' ? '新闻中心' : 'NEWS CENTER'} />
        <div style={{ position: 'absolute', zIndex: 3, top: 0, width: '100%', height: '100%', background: 'linear-gradient(180deg,rgba(0,0,0,.6),rgba(0,0,0,8e-05),rgba(0,0,0,.8))' }}></div>
      </div>
      <div className="news-container__body" style={mobileCheck() ? { padding: 24 } : {}}>
        <Row gutter={[16, 16]} justify="center">
          {newsList.slice((pageNum - 1) * PAGE_SIZE, pageNum * PAGE_SIZE).map(news => {
            return <Col><NewsCard {...news} onClick={() => navigate(`/news/${news.news_uuid}`)} style={{ height: '100%', animation: 'none' }} /></Col>
          })}
        </Row>
      </div>
      <div className="news-container__pagination">
        <Pagination defaultCurrent={1} pageSize={PAGE_SIZE} total={newsList.length} hideOnSinglePage showSizeChanger={false} onChange={(pageNum) => {
          setPageNum(pageNum)
          window.scrollTo({ top: 0 })
        }} />
      </div>
    </div>
  )
}

export default News