import { GlobalOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isElementInViewport, mobileCheck } from '../../utils'
import { QDLions } from '../QDAssets'
import './index.less'

const SectionDivider = ({ color = "default" }) => {
  const schoolVirtualIdentities = useSelector(state => state.website?.config?.school_virtual_identities || {})

  const divRef = useRef()
  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    const el = divRef.current
    const handleScroll = () => {
      if (isElementInViewport(el, 0.75)) {
        setIsInit(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div ref={divRef} style={{ position: 'relative' }}>
      <div className={classNames({
        "section-divider-wrapper": true,
        "section-divider-wrapper__active": isInit
      })} style={{ top: mobileCheck() ? -30 : -42 }}>
        <div className="section-divider-wrapper__vertical" style={{ height: mobileCheck() ? 60 : 84 }}></div>

        {/* <div className={`section-divider-wrapper__mascot section-divider-wrapper__mascot__${color}`}></div> */}
        <img src={schoolVirtualIdentities.logo_badge} alt="badge-logo" height={mobileCheck() ? 36 : 48} />
        {/* <QDLions width="36px" style={{ margin: '43px 0 0' }} /> */}
      </div>
    </div>

  )
}

export default SectionDivider