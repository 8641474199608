import React from 'react';

const flexStyleKeys = [
  'justifyContent',
  'flex',
  'alignItems',
  'alignContent',
  'gap',
  'flexDirection',
  'alignSelf',
  'flexBasis',
  'flexWrap',
];

export const FlexContainer = ({ className, children, style, ...props }) => {
  const flexStyle = {};
  const otherProps = {};

  Object.entries(props).forEach(([k, v]) => {
    if (flexStyleKeys.includes(k)) {
      flexStyle[k] = v;
    } else {
      otherProps[k] = v
    }
  });

  return (
    <div
      className={className}
      {...otherProps}
      style={{
        display: 'flex',
        ...flexStyle,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const FlexItem = ({ className, children, style, ...props }) => {
  const flexStyle = {};
  const otherProps = {};

  Object.entries(props).forEach(([k, v]) => {
    if (flexStyleKeys.includes(k)) {
      flexStyle[k] = v;
    } else {
      otherProps[k] = v
    }
  });

  return (
    <div
      className={className}
      {...otherProps}
      style={{
        ...flexStyle,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
