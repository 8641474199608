import Navbar from "./components/Navbar"
import SchoolSwitcher from "./components/SchoolSwitcher"
import { FlexContainer } from "../FlexComps"
import './index.less'
import { useSelector } from 'react-redux'
import { mobileCheck } from "../../utils"
import { useEffect, useMemo, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classNames from "classnames"
import { Dropdown } from 'antd'

const Header = () => {
  const schoolVirtualIdentities = useSelector(state => state.website?.config?.school_virtual_identities || {})
  const websiteRoutes = useSelector(state => state.website?.config?.website_routes || [])

  const location = useLocation()
  const navigate = useNavigate()
  const [stickyHeader, setStickyHeader] = useState(false)
  const [stickyNav, setStickyNav] = useState(false)

  useEffect(() => {
    const handleScroll = (e) => {
      setStickyHeader(window.scrollY > 0)
      setStickyNav(window.scrollY > window.innerHeight - 115)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const logoAttr = useMemo(() => {
    if (!stickyHeader && !mobileCheck()) {
      return {
        src: schoolVirtualIdentities.logo_horizontal_white,
        width: '360px'
      }
    } else {
      return {
        src: schoolVirtualIdentities.logo_horizontal,
        width: '320px'
      }
    }
  }, [schoolVirtualIdentities, stickyHeader])

  if (mobileCheck()) {
    return (
      <>
        <FlexContainer className={classNames({
          'mobile-header-wrapper': true
        })} justifyContent="space-between" alignItems="center">
          <Link to="/">
            <img {...logoAttr} alt="qdhs-logo" style={{ maxWidth: window.innerWidth - 24 - 120 }} />
          </Link>        
          <Navbar />

        </FlexContainer>
        {window.location.pathname === '/' && (
          <FlexContainer justifyContent="center" flexWrap="wrap" style={{ 
            padding: '18px 30px 16px', 
            ...mobileCheck() ? { top: 65 } : {}
          }} className={classNames({
              'header-wrapper__sticky-mode__nav-header__active': stickyNav,
              'header-wrapper__sticky-mode__nav-header': true
          })}>
              {websiteRoutes.map(route => {
                return (
                  <span key={route.key} className="header-wrapper__sticky-mode__nav-header__span font-weight-bold-zh font-weight-bold-en" onClick={() => {
                    if (route.children?.length) {
                      navigate(`/page/${route.children[0].key}?fromMenuKey=${route.key}`)
                      return
                    }
                    navigate(`/menu/${route.key}`)
                  }}>
                    {route.title}
                  </span>
                )
              })}
          </FlexContainer>
        )}
      </>
    )
  } else {
    return (
      <>
        <FlexContainer className={classNames({
          'header-wrapper': true,
          'header-wrapper__sticky-mode': stickyHeader
        })} justifyContent="space-between" alignItems="center" flexWrap="nowrap" gap="24px">
          <SchoolSwitcher />
          <Link to="/">
            <img {...logoAttr} alt="qdhs-logo" style={{ transition: '.5s' }} />
          </Link>
          <Navbar />
        </FlexContainer>
        {window.location.pathname === '/' && (
          <FlexContainer justifyContent="center" flexWrap="wrap" style={{ padding: '18px 30px 16px' }} className={classNames({
              'header-wrapper__sticky-mode__nav-header__active': stickyNav,
              'header-wrapper__sticky-mode__nav-header': true
          })}>
              {websiteRoutes.map(route => {
                return (
                  <span key={route.key} className="header-wrapper__sticky-mode__nav-header__span font-weight-bold-zh font-weight-bold-en" onClick={() => {
                    if (route.children?.length) {
                      navigate(`/page/${route.children[0].key}?fromMenuKey=${route.key}`)
                      return
                    }
                    navigate(`/menu/${route.key}`)
                  }}>
                    {route.title}
                  </span>
                )
              })}
          </FlexContainer>
        )}
  
        {/* <div className="header-wrapper__mobile-logo">
          {mobileCheck() && <Link to="/"><img {...logoAttr} /></Link>}
        </div> */}
      </>
    )
  }
}

export default Header