import { useAsyncEffect } from "ahooks";
import { Form, Input, Button, DatePicker, Radio, Spin, Select, message, Descriptions } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient } from "../../App";
import './index.less'

const CampusVisitForm = () => {
  const locale = useSelector(state => state.website?.locale)
  
  const [campusVisitDetail, setCampusVisitDetail] = useState()
  const [submitInfo, setSubmitInfo] = useState()

  const [loading, setLoading] = useState(true)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [sendCD, setSendCD] = useState(0)
  const [currPhoneNumber, setCurrPhoneNumber] = useState('')

  const sendCDIntervalRef = useRef()


  useAsyncEffect(async () => {
    const respData = await httpClient.get(`/api/v1/campus-visit`)

    if (respData?.data?.data?.campusVisitList?.length) {
      setCampusVisitDetail(respData.data.data.campusVisitList[0])
      
      const cache = localStorage.getItem(`__campus_visit_submission__${respData.data.data.campusVisitList[0].round_id}__`)
      if (cache) {
        setSubmitInfo(JSON.parse(cache))
      }
    }
    
    setLoading(false)
  }, [])

  const handleCancel = useCallback(async (submitId) => {
    setConfirmLoading(true)
    await httpClient.delete(`/api/v1/campus-visit?submitId=${submitId}`)
    setConfirmLoading(false)
    localStorage.removeItem(`__campus_visit_submission__${campusVisitDetail?.round_id}__`)
    setSubmitInfo(undefined)
    message.success(locale === 'en' ? 'Cancel successfully' : '取消成功')
  }, [campusVisitDetail, locale])

  const handleFinish = useCallback(async (v) => {
    setConfirmLoading(true)
    v.birthday = v.birthday.format('YYYY-MM-DD')

    const respData = await httpClient.post(`/api/v1/campus-visit`, {
      ...v,
      round_id: campusVisitDetail?.round_id
    })

    if (respData?.data?.code !== 0) {
      message.error(respData?.data?.message || 'Unknown error occurred')
    } else {
      const cachedData = {
        submitId: respData?.data?.data,
        reservationInfo: {
          ...JSON.parse(JSON.stringify(v)),
          session: campusVisitDetail?.session_info?.find(session => session.sessionId === v.session)?.label
        }
      }
      localStorage.setItem(`__campus_visit_submission__${campusVisitDetail?.round_id}__`, JSON.stringify(cachedData))
      setSubmitInfo(cachedData)
    }

    setConfirmLoading(false)
  }, [campusVisitDetail])

  const handleSendVerificationCode = useCallback(async () => {
    try {
      const resp = await httpClient.get(`/api/v1/campus-visit/phone-verification?phone=${currPhoneNumber}`)
      if (resp.data?.code !== 0 || resp.status !== 200) {
        message.error(resp.data?.message || 'Unknown error occurred. 发生未知错误。')
      } else {
        message.success(locale === 'en' ? 'Send successfully' : '发送成功')

        setSendCD(60)
        if (sendCDIntervalRef.current) {
          clearInterval(sendCDIntervalRef.current)
          sendCDIntervalRef.current = undefined
        }
        sendCDIntervalRef.current = setInterval(() => {
          setSendCD(prev => {
            if (prev - 1 === 0 && sendCDIntervalRef.current) {
              clearInterval(sendCDIntervalRef.current)
              sendCDIntervalRef.current = undefined
            }
            return prev - 1
          })
        }, 1000)
      }
    } catch(err) {
      console.error(err)
    }
  }, [currPhoneNumber, locale])

  const formFields = useMemo(() => {
    if (!campusVisitDetail) {
      return {}
    }

    return {
      student_name: {
        fieldType: 'input',
        label: locale === 'en' ? 'Student name' : '学生姓名',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }]
      },
      gender: {
        fieldType: 'radio_group',
        label: locale === 'en' ? 'Student gender' : '学生性别',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }],
        fieldProps: {
          options: [
            { label: locale === 'en' ? 'Male' : '男', value: 'male' },
            { label: locale === 'en' ? 'Female' : '女', value: 'female' },
          ]
        }
      },
      birthday: {
        fieldType: 'date_picker',
        label: locale === 'en' ? 'Student birthday' : '学生生日日期',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }]
      },
      current_school: {
        fieldType: 'input',
        label: locale === 'en' ? 'Student current school' : '学生就读初中',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }]
      },
      contact_number: {
        fieldType: 'input',
        label: locale === 'en' ? 'Visitor contact number' : '来访家长联系手机',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }, { pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: locale === 'en' ? 'Invalid phone number' : '请输入正确的手机号' }]
      },
      id_number: {
        fieldType: 'input',
        label: locale === 'en' ? 'Visitor ID number' : '来访家长身份证号码',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }]
      },
      session: {
        fieldType: 'select',
        label: locale === 'en' ? 'Session' : '访校场次',
        rules: [{ required: true, message: locale === 'en' ? 'Required' : '必填项' }],
        fieldProps: {
          options: campusVisitDetail?.session_info?.map(session => ({
            label: `${session.label} ${session.disabled ? locale === 'en' ? '(full)' : '(已满)' : ''}`,
            value: session.sessionId,
            disabled: session.disabled
          })) || []
        }
      },
      email: {
        fieldType: 'input',
        label: locale === 'en' ? 'Visitor email' : '来访家长联系邮箱',
        rules: [{ required: true, type: 'email', message: locale === 'en' ? 'Invalid email' : '请输入正确的邮箱' }]
      }
    }
  }, [locale, campusVisitDetail])

  return (
    <div className="campus-visit-form-container">
      {loading && <Spin tip={locale === 'en' ? 'Loading campus visit detail...' : '正在加载访校预约信息...'} style={{ width: '100%', margin: '24px 0' }} />}

      {!loading && !campusVisitDetail && (
        <h2 style={{ fontSize: 15, margin: '-8px 0' }}>{locale === 'en' ? 'No available campus visit sessions. Try again later.' : '未找到开放的访校场次，请稍后再试。'}</h2>
      )}


      {!loading && campusVisitDetail && submitInfo && (
        <>
          <h2 style={{ fontSize: 15 }}>{locale === 'en' ? "You've already signed up for this campus visit session." : '您已成功预约该访校场次。'}</h2>
          <Descriptions style={{ marginTop: 16 }}>
            {Object.entries(submitInfo?.reservationInfo || {}).filter(([k]) => k !== 'phone_verification').map(([k, v]) => {
              return <Descriptions.Item label={formFields[k]?.label || ''}>{v}</Descriptions.Item>
            })}
          </Descriptions>
          <Button danger onClick={() => handleCancel(submitInfo.submitId)} loading={confirmLoading}>{locale === 'en' ? 'Cancel' : '取消预约'}</Button>
        </>
      )}

      {!loading && campusVisitDetail && !submitInfo && (
        <>
          <h2>{locale === 'en' ? 'Campus visit' : '访校预约'}</h2>
          <Form
            // name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={handleFinish}
            autoComplete="off"
            layout="vertical"
            onValuesChange={v => {
              if (v.contact_number) {
                setCurrPhoneNumber(v.contact_number)
              }
            }}
          >
            {Object.entries(formFields).map(([field, fieldDetail]) => {
              switch (fieldDetail.fieldType) {
                case 'input':
                  return (
                    <Form.Item label={fieldDetail.label} name={field} rules={fieldDetail.rules || []}>
                      <Input />
                    </Form.Item>
                  )
                case 'date_picker': 
                  return (
                    <Form.Item label={fieldDetail.label} name={field} rules={fieldDetail.rules || []}>
                      <DatePicker />
                    </Form.Item>
                  )
                case 'radio_group':
                  return (
                    <Form.Item label={fieldDetail.label} name={field} rules={fieldDetail.rules || []}>
                      <Radio.Group {...fieldDetail.fieldProps} />
                    </Form.Item>
                  )
                case 'select':
                  return (
                    <Form.Item label={fieldDetail.label} name={field} rules={fieldDetail.rules || []}>
                      <Select {...fieldDetail.fieldProps} />
                    </Form.Item>
                  )
                default:
                  return <></>
              }

            })}

            <Form.Item label={locale === 'en' ? 'Phone verification' : '手机验证码'} name="phone_verification" rules={[{ required: true, message: locale === 'en' ? 'Required' : '必填项' }]}>
              <Input type="number" className="campus-visit-form-container__input-number" suffix={<Button disabled={sendCD > 0 || !currPhoneNumber} onClick={handleSendVerificationCode} type="link" size="small">{sendCD <= 0 ? (locale === 'en' ? 'Send' : '发送验证码') : (locale === 'en' ? `Resend after ${sendCD}s` : `重新发送 (${sendCD}s)`)}</Button>} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading} style={{ background: '#CF202F', borderColor: '#CF202F' }}>
                {locale === 'zh' ? '提交' : 'Submit'}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  )
}

export default CampusVisitForm;