import { useAsyncEffect } from "ahooks"
import { admissionClient, httpClient } from "../../App"
import { createContext, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setWebsiteResource } from "../../store/slice/websiteSlice"
import { Button, Card, Form, Input, Spin, message } from "antd"
import './index.less'
import { useTrans } from "../../hooks"
import { FlexContainer } from "../../components/FlexComps"
import { DisconnectOutlined, InfoCircleOutlined } from "@ant-design/icons"
import AdmissionDetail from "../AdmissionDetail"

export const AdmissionDetailContext = createContext({ account_id: '' })

const Admission = () => {
    const [loading, setLoading] = useState(true)
    const [mode, setMode] = useState('login')
    const [sendCd, setSendCd] = useState(0)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)
    const [currUserInfo, setCurrUserInfo] = useState()

    const [formData, setFormData] = useState({})

    const formRef = useRef()
    const dispatch = useDispatch()
    const trans = useTrans()

    const admissionEnabled = useSelector(state => state.website?.admissionConfig?.admission_setting?.enabled)

    useAsyncEffect(async () => {
        const userInfoResp = await admissionClient.get('/api/v1/admission/user-info')
        if (userInfoResp.status === 200 && userInfoResp.data?.code === 0) {
            setCurrUserInfo(userInfoResp.data?.data?.userInfo)
        } else {
            setCurrUserInfo(undefined)
        }

        const globalConfigResp = await httpClient.get('/api/v1/admission/global-config')

        if (globalConfigResp.status !== 200 || globalConfigResp.data?.code !== 0) {
            message.error('something went wrong while loading admission page')
            return
        }

        dispatch(setWebsiteResource({ admissionConfig: globalConfigResp.data.data }))
        setLoading(false)
    }, [])

    const handleSendVerificationCode = async () => {
        try {
            setConfirmLoading(true)
            const resp = await httpClient.get('/api/v1/admission/phone-verification', {
                params: { phone: formData?.phone }
            })

            if (resp.status !== 200 || resp.data?.code !== 0) {
                throw new Error(resp.data?.message || 'n/a')
            }

            message.success(trans('发送成功', 'Sent successfully'))
            setSendCd(5)
            const interval = setInterval(() => {
                setSendCd(prev => {
                    if (prev - 1 === 0) {
                        clearInterval(interval)
                    }

                    return prev - 1
                })
            }, 1000)
        } catch (err) {
            message.error(trans(`发送失败 (${err?.message})`, `Something went wrong when sending phone verification code (${err?.message})`))
            console.error(err)
        } finally {
            setConfirmLoading(false)
        }
    }

    const handleAdmissionLogin = async () => {
        let data
        try {
            data = await formRef.current?.validateFields()
        }
        catch (err) {
            console.error(err)
            return
        }

        try {
            setLoginLoading(true)
            const resp = await admissionClient.post('/api/v1/admission/login', data)
            if (resp.status !== 200 || resp.data?.code !== 0) {
                throw new Error(resp.data?.message || 'n/a')
            }

            setCurrUserInfo({ account_id: data.phone })
            message.success(trans('登录成功', 'Login successfully'))
        } catch (err) {
            message.error(trans(`登录失败 (${err?.message})`, `Something went wrong when logging you in (${err?.message})`))
            console.error(err)
        } finally {
            setLoginLoading(false)
        }
    }

    if (loading) {
        return (
            <FlexContainer className="admission-page-container" justifyContent="center" alignItems="center" style={{ height: '100vh', width: '100%' }}>
                <Card loading bordered style={{ borderRadius: 8 }} bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 360, height: 240 }} />
            </FlexContainer>
        )
    }

    if (currUserInfo) {
        return (
            <AdmissionDetailContext.Provider value={currUserInfo}>
                <AdmissionDetail />
            </AdmissionDetailContext.Provider>
        )
    }

    return (
        <div className="admission-page-container">
            {!admissionEnabled && (
                <FlexContainer justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <Card bordered style={{ borderRadius: 8 }} bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 360, height: 240 }}>
                        <DisconnectOutlined style={{ fontSize: 48, color: '#CF202F' }} />
                        <div style={{ fontSize: 20, marginTop: 24, fontWeight: 600, textAlign: 'center', color: '#13234B' }}>{trans('招生平台入口已关闭。', 'The admission portal is currently closed.')}</div>
                    </Card>
                </FlexContainer>
            )}

            {admissionEnabled && (
                <FlexContainer justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <Card bordered style={{ borderRadius: 8 }} bodyStyle={{ display: 'flex', flexDirection: 'column', width: 360 }}>
                        <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 600, marginBottom: 4, color: '#CF202F' }}>
                            {trans('欢迎报考七宝德怀特！', 'Welcome to apply for Qibao Dwight!')}
                        </div>

                        <div style={{ marginBottom: 12, textAlign: 'center', fontSize: 14, color: '#13234B' }}>
                            {trans('请登录', 'Please log in')}
                        </div>

                        <Form layout="vertical" onValuesChange={setFormData} ref={formRef}>
                            <Form.Item label={trans('手机', 'Phone number')} name="phone" rules={[{ required: true, message: trans('必填项', 'Required') }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={trans('验证码', 'Verification code')} name="code" rules={[{ required: true, message: trans('必填项', 'Required') }]}>
                                <Input suffix={(<Button size="small" type="link" loading={confirmLoading} onClick={handleSendVerificationCode} disabled={sendCd > 0}>{trans('发送', 'Send')} {sendCd > 0 ? `(${sendCd}s)` : ''}</Button>)} />
                            </Form.Item>
                        </Form>

                        <Button loading={loginLoading} type="primary" block onClick={handleAdmissionLogin}>{trans('登录', 'Log in')}</Button>

                        <div style={{ fontSize: 12, color: 'grey', marginTop: 2 }}>
                            <InfoCircleOutlined style={{ marginRight: 4 }} />
                            {trans("未注册手机号登录后将自动注册。", "An unregistered phone number will be automatically registered after verification.")}
                        </div>
                    </Card>
                </FlexContainer>
            )}
        </div>
    )
}

export default Admission