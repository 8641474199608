import { useCallback } from "react"
import { useSelector } from "react-redux"

export const useTrans = () => {
    const locale = useSelector(state => state.website?.locale)

    const trans = useCallback((zh, en) => {
        return locale === 'en' ? en : zh
    }, [locale])

    return trans
}