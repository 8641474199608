import ReactDOM from 'react-dom/client';
import { useEffect, useMemo, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { httpClient, BACKEND_URL } from "../../App"
import { Empty, List, message, Spin, Typography, Skeleton, Carousel, Row, Col } from "antd"
import { EventBlock, FactBlock, FooterBlock, VideoBlock, WelcomeBlock } from '../../components/QDBlock';
import { mobileCheck, parseQuery, useQuery } from "../../utils";
import classNames from "classnames";
import { Provider, useDispatch, useSelector } from "react-redux";
import Header from '../../components/Header';
import { useAsyncEffect } from "ahooks";
import { parse } from 'querystring'

import { FlexContainer } from "../../components/FlexComps";
import './index.less'
import { setWebsiteResource } from "../../store/slice/websiteSlice";
import { EmbededQDButton } from '../../components/QDButton';
import CampusVisitForm from '../../components/CampusVisitForm';
import store from '../../store';
import PosterCard from '../../components/PosterCard';
import NewsCard from '../../components/NewsCard';

const NewsEvent = ({ showInModal, ...props }) => {
  let { news_uuid } = useParams()

  if (!news_uuid) {
    news_uuid = props?.news_uuid
  }

  const dispatch = useDispatch()

  const mainContainerRef = useRef()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState()
  const [is404, setIs404] = useState(false)

  const {
    locale, routes
  } = useSelector(state => ({
    locale: state.website.locale,
    routes: state.website.config.website_routes
  }))
  const query = parse(window.location.search?.slice(1) || '');

  const currMenu = useMemo(() => {
    return routes.find(menu => menu.key === query?.fromMenuKey)
  }, [query, routes])

  useEffect(() => {
    if (!showInModal) {
      document.title = locale === 'zh' ? `${page?.title} - 七宝德怀特` :  `${page?.title} - Qibao Dwight` 
    }
  }, [page, locale, currMenu, showInModal])

  useEffect(() => {
    let timeout
    let counter = 0
    
    const handleClick = () => {
      counter += 1
      timeout && clearTimeout(timeout)

      if (counter === 5) {
        window.open(`${BACKEND_URL}/news/edit/${news_uuid}`)
        counter = 0;
      } else {
        timeout = setTimeout(() => {
          counter = 0
          timeout = undefined
        }, 500)
      }
    }

    document.querySelector(".news-event-wrapper__main-container__header-wrapper")?.addEventListener('click', handleClick)
    return () => {
      document.querySelector(".news-event-wrapper__main-container__header-wrapper")?.removeEventListener('click', handleClick)
    }
  }, [news_uuid])

  useAsyncEffect(async () => {
    try {
      setLoading(true)
      const respData = await httpClient.get(`/api/v1/news?news_uuid=${news_uuid}`)
      if (respData && respData.data && respData.data.code === 0) {
        if (respData.data.data.newsList?.length === 1) {
          setPage(respData.data.data.newsList[0])

          const newLocale = respData.data.data.newsList[0].site_type
          localStorage.setItem("__locale__", newLocale)
          dispatch(setWebsiteResource({ locale: newLocale }))

          setIs404(false)
        } else {
          setIs404(true)
          setPage(undefined)
        }
        setLoading(false)
      } else {
        throw new Error()
      }
    } catch(err) {
      message.error(`something went wrong while loading news ${news_uuid}`)
    }
  }, [news_uuid])

  useEffect(() => {
    if (page) {
      const customEls = [...document.querySelector('.news-event-wrapper__main-container__html-wrapper')?.querySelectorAll('pre') || []]
      for (const el of customEls) {
        try {
          let renderConfig
          eval(`renderConfig = ${el.innerText}`)
          let compToRender = <div></div>

          switch(renderConfig?.type) {
            case "carousel":
              compToRender = (
                <Carousel>
                  {renderConfig.imageList.map((image, idx) => (
                    <img src={image} key={`img-${idx}`} />
                  ))}
                </Carousel>
              )
              break
            case "1_3_button_group":
              compToRender = (
                <Row gutter={[16, 16]} style={{ overflow: 'hidden' }}>
                  {renderConfig?.buttons?.map(btnProps => {

                    btnProps.style = {
                      width: '100%',
                      ...btnProps.style
                    }

                    return (
                        <Col span={8}>
                          <FlexContainer justifyContent="center">
                            <EmbededQDButton {...btnProps} />
                          </FlexContainer>
                        </Col>
                    )
                  })}
                </Row>
              )
              break
            case 'campus_visit_panel': 
              compToRender = (
                <Provider store={store}>
                  <CampusVisitForm />
                </Provider>
              )
              break
            case '1_3_image_group':
              compToRender = (
                <Row gutter={[8, 8]} style={{ overflow: 'hidden' }}>
                {renderConfig?.images?.map(imgProps => {
                  const containerWidth = mainContainerRef.current?.clientWidth
                  const targetHeight = (containerWidth - 16) / 3 / 3 * 2

                  return (
                      <Col span={8}>
                        <FlexContainer justifyContent="center" style={{ position: 'relative' }}>
                          <img src={imgProps.url} width="100%" height={targetHeight} style={{ objectFit: 'cover' }} />
                          <div className="gradient-container">
                            <div className="gradient-container__caption font-weight-bold-zh font-weight-bold-en" style={{ color: 'white', ...imgProps.captionStyle || {} }}>{imgProps.caption}</div>
                          </div>
                        </FlexContainer>
                      </Col>
                  )
                })}
              </Row>
              )
              break
            case '1_3_staff_card':
              compToRender = (
                <Provider store={store}>
                  <Row gutter={[16, 16]} style={{ overflow: 'hidden' }}>
                    {renderConfig?.cards?.map(cardProps => {

                      return (
                        <Col flex="300px">
                          <PosterCard overrideHeight={300} {...cardProps} style={{ animation: 'none', ...cardProps?.style || {} }} />
                        </Col>
                      )
                    })}
                  </Row>
                </Provider>
              )
              break
            case '1_3_news_card':
              compToRender = (
                <Provider store={store}>
                  <FlexContainer gap="16px" flexWrap="nowrap">
                    {renderConfig?.cards?.map(cardProps => {
                      return (
                        <NewsCard {...cardProps} />
                      )
                    })}
                  </FlexContainer>
                </Provider>
              )
              break
            default:
              console.log('no comp to render')
          }

          if (compToRender) {
            const root = ReactDOM.createRoot(el);
            root.render(compToRender);
          }
        } catch(err) {
          console.error('自定义组件解析失败', err)
          const root = ReactDOM.createRoot(el)
          root.render(<div style={{ fontSize: 14 }}>自定义组件解析失败，请检查</div>)
        }
      }
    }
  }, [page])

  return (
    <div>
      <div className="news-event-wrapper__cover-image">
        <img src={page?.cover_url} style={showInModal ? { height: 300 } : {}} />
        <div style={{ position: 'absolute', zIndex: 3, top: 0, width: '100%', height: '100%', background: 'linear-gradient(180deg,rgba(0,0,0,.6),rgba(0,0,0,8e-05),rgba(0,0,0,.8))' }}></div>
      </div>
      <FlexContainer className="news-event-wrapper" style={{ padding: showInModal ? 24 : '24px 0 0 0' }}>
        {loading ? <Skeleton active style={mobileCheck() ? { padding: 24 } : {}} /> :
          <>
            {page && (
              <div className="news-event-wrapper__main-container" ref={mainContainerRef} style={mobileCheck() ? { padding: '0 24px' } : {}}>
                <h1 className="news-event-wrapper__main-container__header-wrapper font-weight-bold-zh font-weight-bold-en">{page.title}</h1>
                <div className="news-event-wrapper__main-container__html-wrapper" dangerouslySetInnerHTML={{ __html: page.body }} />
              </div>
            )}
            {is404 && <Empty />}
          </>
          }
      </FlexContainer>
    </div>
  )
}

export default NewsEvent