import { useRef, useEffect, useState, useMemo } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import QDButton from '../QDButton'
import './index.less'
import { QD_RED_2 } from '../../constants'
import { isElementInViewport, mobileCheck } from '../../utils'

const PosterCard = ({
  image_url,
  title,
  sub_title,
  description,
  page_uuid,
  posterIdx = 0,
  style = {},
  titleStyle = {},
  overlayTitleStyle = {},
  overlayDescriptionStyle = {},
  overrideHeight=undefined
}) => {
  const locale = useSelector(state => state.website?.locale)
  const [isHovered, setIsHovered] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const divRef = useRef()

  useEffect(() => {
    const el = divRef.current

    const handleMouseOver = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    el?.addEventListener('mouseover', handleMouseOver)
    el?.addEventListener('mouseleave', handleMouseLeave)

    if (style?.animation === 'none') {
      setIsInit(true)
      return () => {
        el?.removeEventListener('mouseover', handleMouseOver)
        el?.removeEventListener('mouseleave', handleMouseLeave)
      }
    } else {
      const handleScroll = () => {
        if (isElementInViewport(el)) {
          setTimeout(() => {
            setIsInit(true)
          }, posterIdx * 300)
        }
      }
  
      window.addEventListener('scroll', handleScroll)
  
      return () => {
        el?.removeEventListener('mouseover', handleMouseOver)
        el?.removeEventListener('mouseleave', handleMouseLeave)
  
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [posterIdx, style])

  const calcHeight = useMemo(() => {
    if (overrideHeight) {
      return overrideHeight
    } else if (mobileCheck()) {
      return window.innerWidth / 2 * 1.5
    } else {
      return 500
    }
  }, [overrideHeight])

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    
    let keyframes = `@keyframes posterCardSlideFadeIn {
      0% {
        top: 40px;
        opacity: 0;
      }
      100% {
        top:  -${calcHeight}px;
        opacity: 1
      }
    }
  
    @keyframes posterCardSlideFadeOut {
      0% {
        top: -${calcHeight}px;
        opacity: 1;
      }
      100% {
        top: 40px;
        opacity: 0;
      }
    }`
    
    let style= document.createElement('STYLE');
    style.innerHTML = keyframes;
    head.appendChild(style);
  }, [calcHeight])

  return (
    <div ref={divRef} className={classnames({
      "poster-card-wrapper": true,
      "poster-card-wrapper__visible": isInit
    })} style={{ ...style || {}, ...mobileCheck() || overrideHeight ? {
      height: calcHeight
    } : {} }}>
      <img alt="" className={classnames({
        "poster-card-image-container": true,
        "poster-card-image-container__active": isHovered,
        "poster-card-image-container__leave": !isHovered,
        "ok-img": true,
      })} src={image_url} />

      <div className="poster-card-gradient-container" />
      <div className={classnames({
        "poster-card-background-container": true,
        "poster-card-background-container__active": isHovered,
        "poster-card-background-container__leave": !isHovered
      })} />

      <div className={classnames({
        "poster-card-body": true,
        "poster-card-body__active": isHovered,
        "poster-card-body__leave": !isHovered
      })} style={{ ...mobileCheck() || overrideHeight ? {
      height: calcHeight,
      ...isHovered ? { top: -calcHeight } : {}
    } : {} }}>
        <p style={overlayTitleStyle} className="poster-card-body__title font-weight-bold-zh font-weight-bold-en">{title}</p>
        {sub_title && <p style={{ ...overlayTitleStyle, fontSize: 14, marginTop: -20, marginBottom: -4 }} className="poster-card-body__title font-weight-bold-zh font-weight-bold-en">{sub_title}</p>}
        <p style={overlayDescriptionStyle} className="poster-card-body__description font-weight-regular-zh font-weight-regular-en"><div>{description}</div></p>
        {page_uuid && <QDButton page_uuid={page_uuid} text={locale === 'zh' ? "查看更多" : "DISCOVER MORE"} borderColor="white" color="white" background="transparent" overlayBackgroundColor='white' overlayColor={QD_RED_2} style={{ width: '100%', padding: '15px 20px', fontSize: 14 }} />}
      </div>

      <div className={classnames({
        "poster-card-title": true,
        "font-weight-bold-zh": true,
        "font-weight-bold-en": true,
        "poster-card-title__active": isHovered,
        "poster-card-title__leave": !isHovered
      })} style={{ ...titleStyle || {}, ...mobileCheck() || overrideHeight ? {
      top: -calcHeight - 60
    } : {} }}>
        {title}
      </div>
    </div>
  )
}

export default PosterCard