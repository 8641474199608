import { QD_RED_2 } from "../../constants"
import { FlexContainer } from "../FlexComps"
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Divider } from "antd"
import './index.less'

const QDDivider = ({ text }) => {
  return (
    <FlexContainer alignItems="center" gap="16px" >
      <span className="qd-divider-wrapper__text" style={{ color: QD_RED_2 }}>{text}</span>
      <div style={{ width: '100%', borderTop: '1px solid lightgrey' }}></div>
    </FlexContainer>
  )
}

export const QDCarouselDivider = ({ onNext, onPrev }) => {
  return (
    <FlexContainer alignItems="center" gap="16px">
      <div style={{ width: '100%', borderTop: '1px solid hsla(0,0%,100%,.3)' }}></div>
      <FlexContainer alignItems="center">
        <span className="span-clickable" onClick={() => onPrev?.()}><LeftOutlined /></span>
        <Divider type="vertical" />
        <span className="span-clickable" onClick={() => onNext?.()}><RightOutlined /></span>
      </FlexContainer>
    </FlexContainer>
  )
}

export default QDDivider