import { useContext, useEffect, useMemo, useState } from "react"
import { AdmissionDetailContext } from "../Admission"
import { Button, Card, Descriptions, Drawer, Flex, Menu, Modal, Select, Space, Steps, Tag, Typography, message } from "antd"
import { useTrans } from "../../hooks"
import { FlexContainer } from "../../components/FlexComps"
import { CheckOutlined, CloseOutlined, EditFilled, EditOutlined, EditTwoTone, FileOutlined, IdcardOutlined, InfoCircleFilled, InfoCircleOutlined, InfoCircleTwoTone, LogoutOutlined } from "@ant-design/icons"
import { admissionClient } from "../../App"
import { useSelector } from "react-redux"
import { hashText } from "../../utils"
import copy from "copy-to-clipboard"
import { useAsyncEffect } from "ahooks"
import dayjs from "dayjs"
import QRCode from "qrcode"
import html2canvas from "html2canvas"

const AdmissionDetail = () => {
    const { account_id } = useContext(AdmissionDetailContext)
    const [loading, setLoading] = useState(true)

    const statusList = useSelector(state => state.website?.admissionConfig?.statusList)
    const stepList = useSelector(state => state.website?.admissionConfig?.stepList)
    const current_admission_year = useSelector(state => state.website?.admissionConfig?.admission_setting?.current_admission_year)

    console.log({account_id, current_admission_year})

    const computedApplicantId = hashText(`${account_id}_${current_admission_year}`)

    // const computedApplicantId = '087guqzr'

    const [editDrawerVisible, setEditDrawerVisible] = useState(false)
    const [helpDrawerVisible, setHelpDrawerVisible] = useState(false)
    const [downloadModalVisible, setDownloadModalVisible] = useState(false)
    const [applicantDetail, setApplicantDetail] = useState()

    const trans = useTrans()

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin === 'https://admission-v2.qibaodwight.org') {

                if (event.data === 'application-form-submitted') {
                    window.location.reload()
                }
            }
        }

        window.addEventListener('message', handleMessage);
        
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    const currentStepInfo = useMemo(() => {
        if (!applicantDetail?.status) {
            return stepList.find(item => item.step_id === 1)
        }

        const targetStepId = statusList?.find(item => item.status_id === applicantDetail?.status)?.step_id
        const stepInfo = stepList?.find(item => item.step_id === targetStepId)

        return stepInfo
    }, [applicantDetail, statusList, stepList])

    useAsyncEffect(async () => {
        try {
            const applicantDetailResp = await admissionClient({
                method: 'get',
                url: '/api/v1/admission/applicant-detail',
                params: { applicant_id: computedApplicantId }
            })

            if (applicantDetailResp.status === 200 && applicantDetailResp.data?.code === 0) {
                setApplicantDetail(applicantDetailResp.data?.data)
            } else {
                throw new Error()
            }
        } catch (err) {
            console.error(err)
            message.error("获取考生详情失败，请重试")
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (downloadModalVisible) {
            QRCode.toCanvas(document.getElementById('qrcode'), `${computedApplicantId}__${applicantDetail?.admissionExamInfo?.admission_exam_id}__${applicantDetail?.admissionExamInfo?.invitation_id}`)
        }
    }, [downloadModalVisible, applicantDetail])

    const handleLogout = () => {
        Modal.confirm({
            title: trans('登出提醒', 'Logout reminder'),
            content: trans('你将要退出登陆。确认继续吗？'),
            onOk: async () => {
                await admissionClient.post('/api/v1/admission/logout')
                message.success('登出成功')

                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
        })
    }

    const handleConfirmAdmissionExamInvitation = (invitation_id, isAccepted) => {
        Modal.confirm({
            title: '二次确认',
            content: `你将要${isAccepted ? '确认参加' : '放弃'}本次招生考试。确认继续吗？`,
            onOk: async () => {
                await admissionClient.post('/api/v1/admission/confirm-admission-exam-invitation', {
                    invitation_id,
                    status: isAccepted ? 10 : 20
                })
                setApplicantDetail(prev => ({
                    ...prev,
                    admissionExamInfo: {
                        ...prev.admissionExamInfo || {},
                        status: isAccepted ? 10 : 20
                    }
                }))
                message.success('提交成功')
            }
        })
    }

    const handlePrintTicket = () => {
        const element = document.querySelector(".ticket-table-wrapper");
        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const windowContent = `
                <style>
                    @media print {
                        img {
                            max-width: 100%;
                        }
                    }
                </style>
                <img src="${imgData}" onload="window.print();window.close()" />
            `;
            const printWindow = window.open('', '', 'width=800,height=600');
            printWindow.document.write(windowContent);
            printWindow.document.close();
        });
    }

    return (
        <FlexContainer style={{ height: '100vh' }}>
            <style>{`
                .header-wrapper, .footer-block-wrapper {
                    display: none !important;
                }

                .ant-steps-item-title {
                    font-weight: 600;
                }

                .admission-exam-remark-wrapper p {
                    margin: 0;
                }
            `}</style>

            <div style={{ width: 220, height: '100%', flexShrink: 0 }}>
                <FlexContainer justifyContent="center" style={{ padding: '16px 0', borderRight: '1px solid #f0f0f0' }}>
                    <img src="https://newweb.obs.cn-east-3.myhuaweicloud.com/public/1715355838506-qdhs-logo.png" width="80px" />
                </FlexContainer>

                <Menu
                    defaultSelectedKeys={['1']}
                    style={{ height: 'calc(100% - 104px)' }}
                    items={[
                        { key: '1', icon: <FileOutlined />, label: trans('我的申请', 'My Application') },
                        { key: '2', icon: <LogoutOutlined />, label: trans('退出登陆', 'Logout'), onClick: handleLogout }
                    ]}
                    mode="inline"
                    theme="light"
                />
            </div>

            <FlexContainer flexDirection="column" style={{ background: '#f8f8f8', height: '100vh', width: '100%', padding: 24, overflow: 'auto' }}>
                <FlexContainer alignItems="center" style={{ fontSize: 22, fontWeight: 600, marginBottom: 16, gap: 12 }}>
                    {trans('我的申请', 'My Application')}
                    <Tag style={{ cursor: 'pointer' }} onClick={() => {
                        copy(computedApplicantId)
                        message.success('复制成功')
                    }}>ID: {computedApplicantId}</Tag>

                    {/* <Select options={statusList.map(item => ({ label: item.remark, value: item.status_id }))} value={applicantDetail?.status} onChange={v => setApplicantDetail(prev => ({ ...prev, status: v }))} /> */}
                </FlexContainer>

                <Card style={{ borderRadius: 8 }} bordered loading={loading}>

                    <Steps
                        // direction="vertical"
                        current={stepList.indexOf(currentStepInfo)}
                        items={stepList.map(step => ({
                            title: step.name
                        }))}
                    />

                    <div style={{ marginTop: 24 }}>
                        {currentStepInfo && (
                            <div>
                                {/* {JSON.stringify(currentStepInfo)} */}
                                <Typography.Title level={5}>{currentStepInfo.step_title}</Typography.Title>
                                <Typography.Paragraph>{currentStepInfo.step_description}</Typography.Paragraph>

                                {currentStepInfo.add_ons?.includes('create_application_btn') && (
                                    <Button type="primary" onClick={() => setEditDrawerVisible(true)}>填报材料</Button>
                                )}

                                {currentStepInfo.add_ons?.includes('admission_exam_invitation_pane') && applicantDetail?.admissionExamInfo && (
                                    <>
                                        <Typography.Title level={5}>考试信息</Typography.Title>

                                        <Descriptions
                                            size="small"
                                            labelStyle={{ width: 80, fontWeight: 600 }}
                                            style={{ marginBottom: 6 }}
                                            column={1}
                                        >
                                            <Descriptions.Item label="考试时间">
                                                {dayjs(applicantDetail?.admissionExamInfo?.datetime).format('YYYY-MM-DD HH:mm')}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="备注">
                                                <div className="admission-exam-remark-wrapper" dangerouslySetInnerHTML={{ __html: applicantDetail?.admissionExamInfo?.remark || '-' }} />
                                            </Descriptions.Item>
                                        </Descriptions>

                                        {applicantDetail?.admissionExamInfo?.status === 1 && (
                                            <Space>
                                                <Button type="primary" onClick={() => handleConfirmAdmissionExamInvitation(applicantDetail?.admissionExamInfo?.invitation_id, true)}>确认参加</Button>
                                                <Button type="default" onClick={() => handleConfirmAdmissionExamInvitation(applicantDetail?.admissionExamInfo?.invitation_id, false)}>放弃考试</Button>
                                            </Space>
                                        )}

                                        {applicantDetail?.admissionExamInfo?.status === 20 && (
                                            <FlexContainer alignItems='center' style={{ gap: 6, color: 'red', fontWeight: 600 }}>
                                                <CloseOutlined />
                                                您已确认放弃本次招生考试
                                            </FlexContainer>
                                        )}

                                        {applicantDetail?.admissionExamInfo?.status === 10 && (
                                            <>
                                                <FlexContainer alignItems='center' style={{ gap: 6, color: 'green', fontWeight: 600 }}>
                                                    <CheckOutlined />
                                                    您已确认参加本次招生考试
                                                </FlexContainer>
                                                <Button type="primary" style={{ marginTop: 12 }} onClick={() => setDownloadModalVisible(true)}>查看准考证</Button>
                                            </>
                                        )}
                                    </>
                                )}

                                {currentStepInfo.add_ons?.includes('admission_exam_result_pane') && [100, 200].includes(applicantDetail?.admissionExamInfo?.status) && (
                                    <>
                                        <Typography.Title level={5}>考试结果</Typography.Title>

                                        <Descriptions
                                            size="small"
                                            labelStyle={{ width: 80 }}
                                            style={{ marginBottom: 6 }}
                                            columns={1}
                                            // items={[
                                            //     { span: 24, label: '结果', children: applicantDetail?.admissionExamInfo?.status === 100 ? <span style={{ fontWeight: 600, color: 'green' }}>考试通过</span> : <span style={{ fontWeight: 600, color: 'red' }}>考试不通过</span> },
                                            //     ...applicantDetail?.admissionExamResultInfo?.map(item => ({
                                            //         span: 24, label: item.remark, children: item.letter || item.grade
                                            //     }))
                                            // ]}
                                        >
                                            <Descriptions.Item label="结果">
                                                {applicantDetail?.admissionExamInfo?.status === 100 
                                                    ? <span style={{ fontWeight: 600, color: 'green' }}>考试通过</span>
                                                    : <span style={{ fontWeight: 600, color: 'red' }}>考试不通过</span>}
                                            </Descriptions.Item>
                                            {applicantDetail?.admissionExamResultInfo?.map(item => (
                                                <Descriptions.Item label={item.remark}>{item.letter || item.grade}</Descriptions.Item>
                                            ))}
                                        </Descriptions>
                                    </>
                                )}
                            </div>
                        )}
                    </div>

                </Card>

                {!!applicantDetail?.status && (
                    <>
                        <FlexContainer justifyContent="space-between" alignItems="center">
                            <div style={{ fontSize: 22, fontWeight: 600, margin: '16px 0' }}>
                                {trans('考生申请表', 'Application Form')}
                            </div>

                            <Button type="link" onClick={() => setEditDrawerVisible(true)} icon={<EditOutlined style={{ fontSize: 18, fontWeight: 600 }} />} style={{ fontSize: 18, fontWeight: 600, padding: 0 }}>更新</Button>
                        </FlexContainer>

                        <Card bodyStyle={{ padding: 0, paddingLeft: 24, paddingTop: 8, paddingBottom: 24, height: '100%' }} style={{ overflow: 'hidden', height: '100%', borderRadius: 8 }}>
                            <iframe src={`https://admission-v2.qibaodwight.org/standalone?applicant_id=${computedApplicantId}&readOnly=1`} style={{ border: 'none', width: '100%', height: "100%" }} />
                        </Card>
                    </>
                )}

            </FlexContainer>


            <Drawer
                visible={editDrawerVisible}
                title={!applicantDetail?.status ? '填写申请材料' : "更新申请材料"}
                width="1200px"
                onClose={() => setEditDrawerVisible(false)}
                bodyStyle={{ overflow: 'hidden', padding: 0 }}
                extra={<Button style={{ padding: 0 }} size="small" type="link" icon={<InfoCircleFilled />} onClick={() => setHelpDrawerVisible(true)}>填写注意事项</Button>}
            >
                <iframe src={`https://admission-v2.qibaodwight.org/standalone?applicant_id=${computedApplicantId}`} style={{ border: 'none', width: '100%', height: 'calc(100vh - 57px)' }} />
            </Drawer>

            <Drawer
                visible={helpDrawerVisible}
                title="填写注意事项"
                width="600"
                onClose={() => setHelpDrawerVisible(false)}
                bodyStyle={{ padding: 0, overflow: 'hidden' }}
            >
                <iframe src="http://obs.qibaodwight.org/public/1716305248514-note.pdf" style={{ border: 'none', width: '100%', height: 'calc(100vh - 57px)' }} />
            </Drawer>

            <Modal
                title="查看准考证"
                width="600px"
                visible={downloadModalVisible}
                onCancel={() => setDownloadModalVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="打印准考证"
                onOk={handlePrintTicket}
            >
                <style>{`
                    .ticket-table-wrapper, .ticket-table-wrapper > tr > td {
                        border: 1px solid #eaeaea;
                        border-collapse: collapse;
                        // padding: 8px;
                    }
                `}</style>
                <table className="ticket-table-wrapper">
                    <tr>
                        <td>
                            <canvas id="qrcode" />
                        </td>
                        <td style={{ padding: 12 }}>

                            <Descriptions
                                size="small"
                                layout="vertical"
                                labelStyle={{ width: 80, fontWeight: 600 }}
                                style={{ marginBottom: 6 }}
                                column={1}
                            >
                                <Descriptions.Item label="考生姓名">
                                    {applicantDetail?.chinese_name}
                                </Descriptions.Item>
                                <Descriptions.Item label="考试时间">
                                    {dayjs(applicantDetail?.admissionExamInfo?.datetime).format('YYYY-MM-DD HH:mm')}
                                </Descriptions.Item>
                                <Descriptions.Item label="备注">
                                    <div className="admission-exam-remark-wrapper" dangerouslySetInnerHTML={{ __html: applicantDetail?.admissionExamInfo?.remark || '-' }} />
                                </Descriptions.Item>
                            </Descriptions>
                        </td>
                    </tr>
                </table>
            </Modal>
        </FlexContainer>
    )
}

export default AdmissionDetail