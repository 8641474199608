import './App.less'

import Header from './components/Header';
import { FooterBlock } from './components/QDBlock';
import { useState, useEffect, useRef } from 'react'
import { message, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { setWebsiteResource } from './store/slice/websiteSlice'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames';
import { mobileCheck } from './utils'
import axios from 'axios';
import { createBrowserRouter, Route, Router, RouterProvider, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home'
import Page from './pages/Page'
import NewsEvent from './pages/NewsEvent'
import { useAsyncEffect } from 'ahooks';
import { Menu } from './pages/Menu';
import News from './pages/News';
import { parse } from 'querystring';
import Admission from './pages/Admission';

// const baseURL = 'http://localhost:7002'
const baseURL = window.location.origin

export const httpClient = axios.create({
  baseURL
})

export const admissionClient = axios.create({
  baseURL,
  withCredentials: true
})

export const BACKEND_URL = 'https://bg.qibaodwight.org'


function App() {
  const { hideFirstVisitPopup, simpleLayout } = parse(window.location.search.slice(1))

  const [loading, setLoading] = useState(true)
  const [animationEndFlag, setAnimationEndFlag] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const locale = useSelector(state => state.website?.locale)
  const { show_in_grayscale } = useSelector(state => state.website?.config?.is_grayscale || {})
  const first_visit_popup_config = useSelector(state => state.website?.config?.first_visit_popup_config || {})

  const dispatch = useDispatch()
  const location = useLocation()

  const prevPath = useRef('')

  useEffect(() => {
    document.body.className = `App__${locale}`
  }, [locale])

  useEffect(() => {
    if (show_in_grayscale) {
      document.body.style.filter = 'grayscale(1)'
    } else {
      document.body.style.filter = 'none'
    }

    const isAdmissionPage = window.location.pathname.startsWith('/admission')

    // 如果enable
    if (first_visit_popup_config?.enabled && !hideFirstVisitPopup && !isAdmissionPage) {
      // 如果配置有效
      if (first_visit_popup_config.page_uuid && first_visit_popup_config.title) {
        const localData = localStorage.getItem('__first_visit_popup_ts__')
        // 如果localData不为空
        if (localData) {
          const jsonData = JSON.parse(localData)

          // 如果title不同，直接展示
          if (jsonData.title !== first_visit_popup_config.title) {
            setModalVisible(true)
          } else if (Date.now() / 1000 > jsonData.showAfter) {
            setModalVisible(true)
          }
        // 否则直接展示
        } else {
          setModalVisible(true)
        }
      }
    }
  }, [show_in_grayscale, first_visit_popup_config])

  useAsyncEffect(async () => {
    try {
      setLoading(true)

      let respData
      const cacheData = localStorage.getItem(`__site_resource_${locale}__`)
      if (cacheData) {
        respData = JSON.parse(cacheData)
        httpClient.get(`/api/v1/resource?site_type=${locale}`).then(respData => {
          localStorage.setItem(`__site_resource_${locale}__`, JSON.stringify(respData))
        })
      } else {
        respData = await httpClient.get(`/api/v1/resource?site_type=${locale}`)
        localStorage.setItem(`__site_resource_${locale}__`, JSON.stringify(respData))
      }

      if (respData && respData.data && respData.data.code === 0) {
        dispatch(setWebsiteResource({
          ...respData.data.data,
          locale: localStorage.getItem('__locale__') || 'zh'
        }))
        setLoading(false)
        setTimeout(() => {
          setAnimationEndFlag(true)
        }, 500)
      } else {
        throw new Error()
      }
    } catch(err) {
      message.error('something went wrong while loading home page')
    }
  }, [locale, dispatch])

  useEffect(() => {
    console.log(location)
    
    let top = 0
    if (prevPath.current.startsWith('/page/') && location.pathname.startsWith('/page/')) {
      if (window.scrollY !== 0) {
        top = Math.min(window.scrollY, window.innerHeight - 68 - (mobileCheck() ? 60 : 80))
      } else {
        top = window.innerHeight - 68 - (mobileCheck() ? 60 : 80)
      }
    }

    window.scrollTo({
      top,
      behavior: 'smooth'
    });

    prevPath.current = location.pathname
  }, [location])

  return (
    <div>
      <div className={classNames({
        "loading-container": true,
        "loading-container__completed": !loading
      })} style={{ display: animationEndFlag ? 'none' : 'flex' }}>
        <img src="https://newweb.obs.cn-east-3.myhuaweicloud.com/public/qdhs-logo.png" alt="qdhs-logo" width="200px" />
        <div className="loading-container__tip">
          <LoadingOutlined style={{ marginRight: 8 }} />
          <span>{locale === 'zh' ? '加载中' : 'LOADING'}</span>
        </div>
      </div>
      {!loading && (
        <div className={classNames({ 
          'App': true, 
          'App__is-mobile': mobileCheck(),
        })}>
          <div>
            {!simpleLayout && <Header />}
            <Routes onUpdate={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }}>
              <Route path="/" element={!loading && <Home />} />
              <Route path="/page/:page_uuid" element={!loading && <Page />} />
              <Route path="/event/:event_uuid" element={!loading && <NewsEvent />} />
              <Route path="/news/:news_uuid" element={!loading && <NewsEvent />} />
              <Route path="/menu/:menu_id" element={!loading && <Menu />} />
              <Route path="/news" element={!loading && <News />} />
              <Route path="/admission" element={!loading && <Admission />} />
            </Routes>
            {!simpleLayout && <FooterBlock />}

            <Modal open={modalVisible} title={first_visit_popup_config?.title} width="760px" footer={null} maskClosable={false} onCancel={() => {
              setModalVisible(false)
              localStorage.setItem('__first_visit_popup_ts__', JSON.stringify({
                showAfter: `${Math.floor(Date.now()/1000) + first_visit_popup_config.cooldown_seconds}`,
                title: first_visit_popup_config.title
              }))
            }} centered>
              <iframe title={first_visit_popup_config?.page_uuid} src={`/page/${first_visit_popup_config?.page_uuid}?hideFirstVisitPopup=1&simpleLayout=1`} style={{ border: 'none', width: '100%', height: '70vh' }} />
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
}


export default App;
