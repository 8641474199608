import { useState, useRef, useEffect } from 'react'
import { Carousel } from 'antd'
import { PauseOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import QDButton from '../QDButton'
import { QD_RED_2 } from '../../constants'
import './index.less'
import { mobileCheck } from '../../utils'
import QDHeader from '../QDHeader'

const contentStyle = {
  height: mobileCheck() ? '100vh' : '100vh',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#fff',
};

export const CarouselIntro = ({ text, page_uuid }) => {
  const locale = useSelector(state => state.website?.locale)

  return (
    <div className="qd-carousel-intro-wrapper">
      <QDHeader 
        className="font-weight-bold-zh font-weight-black-en" 
        header={text} 
        color="white" 
        style={{ fontSize: mobileCheck() ? 36 : 48, marginBottom: page_uuid ? mobileCheck() ? 16 : 35 : 0 }} 
      />
      {page_uuid && <QDButton 
        borderColor='white' 
        backgroundColor='transparent'
        color='white'
        overlayColor={QD_RED_2}
        overlayBackgroundColor="white"
        text={locale === 'zh' ? "查看更多" : "Discover"}
        page_uuid={page_uuid}
        style={{ fontSize: '0.8125rem', textTransform: 'uppercase', padding: mobileCheck() ? '8px 24px' : '17px 32px' }}
      />}
    </div>
  )
}

const QDCarousel = () => {

  const [isPaused, setIsPaused] = useState(false)
  const imageList = useSelector(state => {
    return state.website?.config?.website_carousel_images || []
  })

  const carouselRef = useRef()
  
  useEffect(() => {
    const firstItem = imageList?.[0]

    if (firstItem && firstItem.image_url.toLowerCase().includes('.mp4')) {
      document.getElementById(`carousel-item-0`)?.play?.()
      setIsPaused(true)
    }
  }, [imageList])

  return (
    <div className="qd-carousel-wrapper">
      <Carousel ref={carouselRef} autoplay={!isPaused} height="100%" afterChange={(nextIdx) => {
        const nextItem = imageList[nextIdx]

        if (nextItem.image_url.toLowerCase().includes('.mp4')) {
          document.getElementById(`carousel-item-${nextIdx}`)?.play?.()
          setIsPaused(true)
        }
      }} beforeChange={(prevIdx) => {
        const prevItem = imageList[prevIdx]

        if (prevItem.image_url.toLowerCase().includes('.mp4')) {
          document.getElementById(`carousel-item-${prevIdx}`)?.pause?.()
          setIsPaused(false)
        }
      }}>

        {
          imageList.map((image, idx) => {
            const isMp4 = image.image_url.toLowerCase().includes('.mp4')
            return (
              <div className="qd-carousel-item" key={`carousel-item-${idx}`}>
                <div style={contentStyle}>
                  
                  {!isMp4 
                    ? <img src={image.image_url} alt={image.title} width="100%" height="100%" style={{ objectFit: 'cover' }} /> 
                    : (
                      <video
                        id={`carousel-item-${idx}`}
                        src={image.image_url}
                        muted 
                        // loop
                        onEnded={() => setIsPaused(false)}
                        style={{ width: '100%', height: '100%', background: 'black', objectFit: 'cover' }}>
                      </video>
                    )
                  }
                </div>
                <CarouselIntro text={image.title} page_uuid={image.page_uuid} />
                <div style={{ position: 'absolute', zIndex: 3, top: 0, width: '100%', height: '100%', background: 'linear-gradient(180deg,rgba(0,0,0,.6),rgba(0,0,0,8e-05),rgba(0,0,0,.8))' }}></div>
              </div>
              
            )
          })
        }
      </Carousel>

      <div className="qd-carousel-control" onClick={() => {
        setIsPaused(!isPaused)
      }}>
        {/* <span className={isPaused ? 'qd-carousel-pause-icon' : 'qd-carousel-play-icon'} /> */}
        {isPaused ? <CaretRightOutlined /> : <PauseOutlined />}
      </div>
    </div>
  )
}

export default QDCarousel