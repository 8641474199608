import { memo, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Link, useNavigate, useParams } from "react-router-dom"
import { httpClient, BACKEND_URL } from "../../App"
import { Empty, List, message, Spin, Typography, Skeleton, Popover, Col, Row, Carousel } from "antd"
import { EventBlock, FactBlock, FooterBlock, VideoBlock, WelcomeBlock } from '../../components/QDBlock';
import { mobileCheck, parseQuery, useQuery } from "../../utils";
import classNames from "classnames";
import { Provider, useDispatch, useSelector } from "react-redux";
import Header from '../../components/Header';
import { useAsyncEffect } from "ahooks";
import Cookies from 'js-cookie'
import { parse } from 'querystring'
import _ from 'lodash'

import { FlexContainer } from "../../components/FlexComps";
import './index.less'
import { CarouselIntro } from "../../components/QDCarousel";
import tocbot from "tocbot";
import PosterCard from "../../components/PosterCard";
import QDButton, { EmbededQDButton } from "../../components/QDButton";
import NewsCard from "../../components/NewsCard";
import store from "../../store";
import CampusVisitForm from "../../components/CampusVisitForm";
import { setWebsiteResource } from "../../store/slice/websiteSlice";

const Page = () => {
  const { simpleLayout } = parse(window.location.search.slice(1))
  const { page_uuid } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState()
  const [is404, setIs404] = useState(false)
  const [tocContainerStyle, setTocContainerStyle] = useState({ 
    paddingLeft: Math.max(0, window.innerWidth - 1200) / 2 + 12, 
    width: 360 + Math.max(0, window.innerWidth - 1200) / 2
  })

  const mainContainerRef = useRef()

  const {
    routes, locale
  } = useSelector(state => ({
    locale: state.website.locale,
    routes: state.website.config.website_routes
  }))
  const query = parse(window.location.search?.slice(1) || '');

  const currMenu = useMemo(() => {
    return routes.find(menu => menu.key === query?.fromMenuKey)
  }, [query, routes])

  const dispatch = useDispatch()

  useEffect(() => {
    if (page_uuid === '__preview__') {
      return
    }

    let timeout
    let counter = 0
    
    const handleClick = () => {
      counter += 1
      timeout && clearTimeout(timeout)

      if (counter === 5) {
        window.open(`${BACKEND_URL}/page/edit/${page_uuid}`)
        counter = 0;
      } else {
        timeout = setTimeout(() => {
          counter = 0
          timeout = undefined
        }, 500)
      }
    }

    document.querySelector(".page-wrapper__main-container__header-wrapper")?.addEventListener('click', handleClick)
    return () => {
      document.querySelector(".page-wrapper__main-container__header-wrapper")?.removeEventListener('click', handleClick)
    }
  }, [page_uuid, loading])

  const hasHeading = useMemo(() => {
    if (page && page.type === 'custom') {
      return ['</h1>', '</h2>', '</h3>', '</h4>', '</h5>', '</h6>'].some(tag => page.content?.includes(tag))
    } else {
      return true
    }
  }, [page])

  useEffect(() => {
    const handleResize = () => {
      setTocContainerStyle({ 
        paddingLeft: Math.max(0, window.innerWidth - 1200) / 2 + 12, 
        width: 360 + Math.max(0, window.innerWidth - 1200) / 2
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (page && hasHeading && !simpleLayout) {
      tocbot.init({
        headingsOffset: 160,
        scrollSmoothOffset: -160,
        tocSelector: '.page-wrapper__toc-container__body',
        contentSelector: page.type === 'facultyList' ? '.page-wrapper__main-container__faculty-list-wrapper' : '.page-wrapper__main-container__html-wrapper',
        headingSelector: 'h1, h2, h3, h4, h5, h6',
      })
    }
  }, [page, hasHeading])

  useEffect(() => {
    document.title = locale === 'zh' ? `${page?.name} - ${currMenu?.title} - 七宝德怀特` :  `${page?.name} - ${currMenu?.title} - Qibao Dwight` 
  }, [page, locale, currMenu])

  useAsyncEffect(async () => {
    if (page_uuid !== '__preview__') {
      try {
        setLoading(true)
        const respData = await httpClient.get(`/api/v1/page?page_uuid=${page_uuid}`)
        if (respData && respData.data && respData.data.code === 0) {
          if (respData.data.data.pageList?.length === 1) {
            respData.data.data.pageList[0].content = respData.data.data.pageList[0].content
            setPage(respData.data.data.pageList[0])

            const newLocale = respData.data.data.pageList[0].site_type
            localStorage.setItem("__locale__", newLocale)
            dispatch(setWebsiteResource({ locale: newLocale }))

            setIs404(false)
          } else {
            setIs404(true)
            setPage(undefined)
          }
          setLoading(false)
        } else {
          throw new Error()
        }
      } catch(err) {
        message.error(`something went wrong while loading page ${page_uuid}`)
      }
    } else {
      window.addEventListener("message", (event) => {
        if (event.data?.type === 'handlePreview') {
          setPage(event.data.page)
          setIs404(false)
          setLoading(false)
        }
      }, false)
    }
  }, [page_uuid])

  useEffect(() => {
    if (page) {
      const customEls = [...document.querySelector('.page-wrapper__main-container__html-wrapper')?.querySelectorAll('pre') || []]
      for (const el of customEls) {
        try {
          let renderConfig
          eval(`renderConfig = ${el.innerText}`)
          let compToRender = <div></div>

          switch(renderConfig?.type) {
            case "carousel":
              compToRender = (
                <Carousel>
                  {renderConfig.imageList.map((image, idx) => (
                    <img src={image} key={`img-${idx}`} />
                  ))}
                </Carousel>
              )
              break
            case "1_3_button_group":
              compToRender = (
                <Row gutter={[16, 16]} style={{ overflow: 'hidden' }}>
                  {renderConfig?.buttons?.map(btnProps => {

                    btnProps.style = {
                      width: '100%',
                      ...btnProps.style
                    }

                    return (
                        <Col span={8}>
                          <FlexContainer justifyContent="center">
                            <EmbededQDButton {...btnProps} />
                          </FlexContainer>
                        </Col>
                    )
                  })}
                </Row>
              )
              break
            case 'campus_visit_panel': 
              compToRender = (
                <Provider store={store}>
                  <CampusVisitForm />
                </Provider>
              )
              break
            case '1_3_image_group':
              compToRender = (
                <Row gutter={[8, 8]} style={{ overflow: 'hidden' }}>
                {renderConfig?.images?.map(imgProps => {
                  const containerWidth = mainContainerRef.current?.clientWidth
                  const targetHeight = (containerWidth - 16) / 3 / 3 * 2

                  return (
                      <Col span={8}>
                        <FlexContainer justifyContent="center" style={{ position: 'relative' }}>
                          <img src={imgProps.url} width="100%" height={targetHeight} style={{ objectFit: 'cover' }} />
                          <div className="gradient-container">
                            <div className="gradient-container__caption font-weight-bold-zh font-weight-bold-en" style={{ color: 'white', ...imgProps.captionStyle || {} }}>{imgProps.caption}</div>
                          </div>
                        </FlexContainer>
                      </Col>
                  )
                })}
              </Row>
              )
              break
            case '1_3_staff_card':
              compToRender = (
                <Provider store={store}>
                  <Row gutter={[16, 16]} style={{ overflow: 'hidden' }}>
                    {renderConfig?.cards?.map(cardProps => {

                      return (
                        <Col flex="300px">
                          <PosterCard overrideHeight={300} {...cardProps} style={{ animation: 'none', ...cardProps?.style || {} }} />
                        </Col>
                      )
                    })}
                  </Row>
                </Provider>
              )
              break
            case '1_3_news_card':
              compToRender = (
                <Provider store={store}>
                  <FlexContainer gap="16px" flexWrap="nowrap">
                    {renderConfig?.cards?.map(cardProps => {
                      return (
                        <NewsCard {...cardProps} />
                      )
                    })}
                  </FlexContainer>
                </Provider>
              )
              break
            default:
              console.log('no comp to render')
          }

          if (compToRender) {
            // el.tagName = 'div'
            const root = ReactDOM.createRoot(el);
            root.render(compToRender);
          }
        } catch(err) {
          console.error('自定义组件解析失败', err)
          const root = ReactDOM.createRoot(el)
          root.render(<div style={{ fontSize: 14 }}>自定义组件解析失败，请检查</div>)
        }
        // console.log(JSON.parse(el.innerText))
        // const root = ReactDOM.createRoot(el);
        // root.render(
        //   <div>1234</div>
        // );
      }
    }
  }, [page])

  const getPageStruct = (page) => {
    if (page.type === 'custom') {
      return (
        <div ref={mainContainerRef} className="page-wrapper__main-container" style={mobileCheck() ? { padding: 24, marginTop: 0 } : {}}>
          <h1 className="page-wrapper__main-container__header-wrapper font-weight-bold-zh font-weight-bold-en">{page.name}</h1>
          <div className="page-wrapper__main-container__html-wrapper" dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
      )
    } else if (page.type === 'facultyList') {
      return (
        <div ref={mainContainerRef} className="page-wrapper__main-container" style={mobileCheck() ? { padding: 24, marginTop: 0 } : {}}>
          <h1 className="page-wrapper__main-container__header-wrapper font-weight-bold-zh font-weight-bold-en">{page.name}</h1>
          <div className="page-wrapper__main-container__faculty-list-wrapper">
              {/* {Object.entries(_.groupBy(page.content || {}, 'faculty')).map(([faculty, staffList]) => (
                <div style={{ marginBottom: 24 }}>
                  <h1 id={faculty} style={{ color: '#13234B', fontWeight: 600, fontSize: 24 }}>{faculty}</h1>
                  <Row gutter={[16, 16]}>
                    {staffList.map(staff => (
                      <Col span={8} key={staff.avatar_url}>
                        <PosterCard style={{ height: 330, animation: 'none' }} image_url={staff.avatar_url} title={staff.header} description={staff.description} overlayDescriptionStyle={{ fontSize: 14 }} overlayTitleStyle={{ fontSize: 18 }} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ))} */}

              {/* image_url: any;
    title: any;
    description: any;
    page_uuid: any;
    posterIdx?: number | undefined;
    style?: {} | undefined;
    titleStyle?: {} | undefined;
    overlayTitleStyle?: {} | undefined;
    overlayDescriptionStyle?: {} | undefined;
    overrideHeight?: undefined; */}

              {page.content?.map(department => (
                <>
                  <h1 id={department?.departmentName}>{department?.departmentName}</h1>
                  {department?.facultyList?.map(faculty => (
                    <div key={faculty?.facultyName} style={{ marginBottom: 12 }}>
                      <h2 id={faculty?.facultyName}>{faculty?.facultyName}</h2>
                      <Row gutter={[16, 16]}>
                        {faculty?.staffList?.map(staff => (
                          <Col span={mobileCheck() ? 12 : 8}>
                            <PosterCard 
                              image_url={staff?.avatar_url} 
                              title={staff?.header}
                              sub_title={staff?.sub_header}
                              description={staff?.description} 
                              overlayDescriptionStyle={{ fontSize: 14 }} 
                              overlayTitleStyle={{ fontSize: 20 }}
                              titleStyle={{ fontSize: 20 }}
                              style={{ animation: 'none' }} 
                              overrideHeight={300} 
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </>
              ))}
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      {!simpleLayout && (
        <div className="page-wrapper__cover-image">
          <img style={!currMenu ? { height : 300 } : {}} src={currMenu?.cover_url || "https://tais.obs.cn-east-3.myhuaweicloud.com/public/upload/2022-09/29/%E6%AF%95%E4%B8%9A2022.jpg"} alt="page cover" />
          {currMenu && <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/menu/${currMenu.key}`)}><CarouselIntro text={currMenu.title} /></div>}
          <div style={{ position: 'absolute', zIndex: 3, top: 0, width: '100%', height: '100%', background: 'linear-gradient(180deg,rgba(0,0,0,.6),rgba(0,0,0,8e-05),rgba(0,0,0,.8))' }}></div>
        </div>
      )}
      {
        currMenu && !simpleLayout && (
          <FlexContainer justifyContent="center" flexWrap="wrap" className="page-wrapper__current-menu" style={{ top: mobileCheck() ? 65 : 85 }}>
            {currMenu.children.map(item => (
              <div className={classNames({
                "font-weight-bold-zh": true,
                "font-weight-bold-en": true,
                "page-wrapper__current-menu-item": true,
                "page-wrapper__current-menu-item__active": page_uuid === item.key
              })}>
                <Link to={`/page/${item.key}?fromMenuKey=${query.fromMenuKey}`}>
                  {item.title}
                </Link>
              </div>
            ))}
          </FlexContainer>
        )
      }
      <div>
        <FlexContainer className="page-wrapper" style={hasHeading && !loading && !is404 ? { maxWidth: 1200 + tocContainerStyle.paddingLeft || 0 } : { margin: 'auto', maxWidth: 1200 }}>
          {/* {currMenu && (
            <List
              header={<span className="page-wrapper__current-menu-list-title">{currMenu.title}</span>}
              bordered
              dataSource={currMenu.children || []}
              renderItem={(item) => (
                <Link to={`/page/${item.key}?fromMenuKey=${query.fromMenuKey}`}>
                  <List.Item className={classNames({
                    'page-wrapper__current-menu-list-item': true,
                    'page-wrapper__current-menu-list-item__active': page_uuid === item.key
                  })}>
                    {item.title}
                  </List.Item>
                </Link>
              )}
            />
          )} */}

          {loading ? <Skeleton active style={{ padding: mobileCheck() ? '24px' : '48px 0' }} /> :
            <>
              {page && !simpleLayout && hasHeading && !mobileCheck() && (
                <div className="page-wrapper__toc-container" style={tocContainerStyle}>
                  <div className="page-wrapper__toc-container__body"></div>
                </div>
              )}
              {page && getPageStruct(page)}
              {is404 && <Empty style={{ padding: '96px 0', width: '100%' }} description={'404 NOT FOUND'} />}
            </>
            }
        </FlexContainer>
      </div>
    </div>
  )
}

export default Page

