import { List } from "antd"
import classNames from "classnames"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { CarouselIntro } from "../../components/QDCarousel"
import './index.less'

export const Menu = () => {
  const { menu_id } = useParams()
  const locale = useSelector(state => state.website?.locale)
  const websiteRoutes = useSelector(state => state.website?.config?.website_routes || [])

  const currMenu = useMemo(() => {
    return websiteRoutes?.find(menu => menu.key === menu_id)
  }, [menu_id, websiteRoutes])

  useEffect(() => {
    document.title = locale === 'zh' ? `${currMenu?.title} - 七宝德怀特` :  `${currMenu?.title} - Qibao Dwight` 
  }, [currMenu, locale])

  return (
    <div className="menu-container">
      {/* {JSON.stringify(currMenu)} */}
      {currMenu && (
        <div>
          <div className="menu-container__cover-image">
            <img src={currMenu?.cover_url || "https://tais.obs.cn-east-3.myhuaweicloud.com/public/upload/2022-09/29/%E6%AF%95%E4%B8%9A2022.jpg"} alt="page cover image" />
            {currMenu && <CarouselIntro text={currMenu.title} />}
            <div style={{ position: 'absolute', zIndex: 3, top: 0, width: '100%', height: '100%', background: 'linear-gradient(180deg,rgba(0,0,0,.6),rgba(0,0,0,8e-05),rgba(0,0,0,.8))' }}></div>
          </div>
          <div className="menu-container__current-menu-list">
            <List     
              // bordered
              dataSource={currMenu.children || []}
              renderItem={(item) => (
                <Link to={`/page/${item.key}?fromMenuKey=${menu_id}`}>
                  <List.Item className={classNames({
                    'menu-container__current-menu-list-item': true,
                  })}>
                    {item.title}
                  </List.Item>
                </Link>
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}