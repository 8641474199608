import { useRef, useEffect, useState } from 'react'
import { Card, Typography } from 'antd'
import QDDivider from '../QDDivider'
import './index.less'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isElementInViewport, mobileCheck } from '../../utils'

const NewsCard = ({ category, title, summary, cover_url, news_uuid, newsIdx, onClick, style }) => {
  const locale = useSelector(state => state.website?.locale)

  const [isInit, setIsInit] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const cardRef = useRef()

  useEffect(() => {
    const el = cardRef.current

    const handleMouseOver = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    el?.addEventListener('mouseover', handleMouseOver)
    el?.addEventListener('mouseleave', handleMouseLeave)

    const handleScroll = () => {
      if (isElementInViewport(el)) {
        setTimeout(() => {
          setIsInit(true)
        }, newsIdx * 300)
      }
    }

    window.addEventListener('scroll', handleScroll)
    window.dispatchEvent(new Event('scroll'))

    return () => {
      el?.removeEventListener('mouseover', handleMouseOver)
      el?.removeEventListener('mouseleave', handleMouseLeave)

      window.removeEventListener('scroll', handleScroll)
    }
  }, [newsIdx])

  return (
    <Card
      className={classNames({
        "event-card-wrapper": true,
        "event-card-wrapper__active": style?.animation === 'none' || isInit
      })}
      ref={cardRef}
      cover={
        <img
          className={classNames({
            "event-card-image-container": true,
            "event-card-image-container__active": isHovered,
            "event-card-image-container__leave": !isHovered,
          })}
          alt={title}
          src={cover_url}
        />
      }
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation() 
        onClick(news_uuid)
      }}
      style={{ 
        ...(style || {}),
        ...mobileCheck() ? {
          width: window.innerWidth - 60
        } : {}
      }}
      bodyStyle={{ padding: '24px 30px' }}
    >
      <div className="font-weight-medium-zh font-weight-medium-en">
        <QDDivider text={<span style={{ fontSize: 18 }}>{category}</span>} />
      </div>
      <h1 className="event-card-wrapper__header font-weight-bold-zh font-weight-bold-en">{title}</h1>
      <Typography.Paragraph ellipsis={{ rows: 7 }} className="event-card-wrapper__summary font-weight-regular-zh font-weight-regular-en">
        {summary}
      </Typography.Paragraph>

      <div className="event-card-wrapper__footer" style={{
        ...mobileCheck() ? {
          width: window.innerWidth - 48 - 48
        } : {}
      }}>
        <div 
          className={classNames({
            "event-card-wrapper__footer__arrow": true,
            "event-card-wrapper__footer__arrow__active": isHovered,
            "event-card-wrapper__footer__arrow__leave": !isHovered,
          })}
        >
        </div>
        <div 
          className={classNames({
            "font-weight-medium-zh": true,
            "font-weight-bold-en": true,
            "event-card-wrapper__footer__read-more": true,
            "event-card-wrapper__footer__read-more__active": isHovered,
            "event-card-wrapper__footer__read-more__leave": !isHovered,
          })}
        >
          {locale === 'zh' ? '阅读更多' : 'READ MORE'}
        </div>
      </div>
    </Card>
  )
}

export default NewsCard