import { createSlice } from '@reduxjs/toolkit'

export const websiteSlice = createSlice({
  name: 'website',
  initialState: {
    locale: localStorage.getItem('__locale__') || 'zh',
    eventList: [],
    newsList: [],
    config: {
      is_grayscale: { show_in_grayscale: false }, 
      website_routes: [],
      website_carousel_images: [],
      welcome_block_cards: [],
      welcome_block_texts: {
        "header": "WELCOME TO",
        "sub_header": "QIBAO DWIGHT",
        "paragraph": "Loading..."
      },
      fact_block_texts: {
        "background_image_url": "",
        "header": "DWIGHT FACTS & FIGURES",
        "page_uuid": '',
        "paragraph": "loading...",
        "btn_text": "MORE FACTS"
      },
      fact_block_figures: [],
      video_block_texts: {
        "video_url": "",
        "poster_url": "",
        "header": "MEETING OF MINDS",
        "paragraph": "Loading...",
        "btn_text": "WATCH_VIDEO"
      },
      school_virtual_identities: {
        logo: "https://newweb.obs.cn-east-3.myhuaweicloud.com/public/qdhs-logo.png",
        logo_white: "https://newweb.obs.cn-east-3.myhuaweicloud.com/public/qdhs-logo-white.png",
        logo_horizontal: "https://newweb.obs.cn-east-3.myhuaweicloud.com/public/horizontal-logo.png",
        logo_horizontal_white: "https://tais.obs.cn-east-3.myhuaweicloud.com/public/qdhs-logo-horizontal-white.png",
        logo_badge: "https://newweb.obs.cn-east-3.myhuaweicloud.com/public/badge-logo .png"
      },
      footer_block_popular_link: [],
      first_visit_popup_config: { 
        enabled:false,
        cooldown_seconds:86400,
        title:"",
        page_uuid:""
      }
    },
    admissionConfig: {
      admission_setting: {
        admission_year_list: [],
        current_admission_year: '',
        enabled: false,
      },
      application_form_schema: [],
      statusList: []
    },

  },
  reducers: {
    setWebsiteResource: (state, data) => {
      Object.assign(state, data.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setWebsiteResource } = websiteSlice.actions

export default websiteSlice.reducer