import { useState, useRef, useMemo, useEffect } from 'react'
import { QD_BLUE_1, QD_RED_1, QD_RED_2 } from '../../constants'
import PosterCard from '../PosterCard'
import QDButton from '../QDButton'
import { ContainerWithBackgroundImage, ContainerWithGradientBackground } from "../QDContainer"
import { QDCarouselDivider } from '../QDDivider'
import QDHeader from '../QDHeader'
import QDParagraph from '../QDParagraph'
import { Carousel, Col, Divider, Modal, Row } from 'antd'
import './index.less'
import QDFactStats from '../QDFactStats'
import { CloseOutlined, GlobalOutlined, VerifiedOutlined } from '@ant-design/icons'
import NewsCard from '../NewsCard'
import { useSelector } from 'react-redux'
import { chunkMaxLength, isElementInViewport, mobileCheck } from '../../utils'
import { FlexContainer } from '../FlexComps'
import classNames from 'classnames'
import NewsEvent from '../../pages/NewsEvent'
import { useNavigate } from 'react-router-dom'

export const WelcomeBlock = () => {
  const { posterList, texts } = useSelector(state => ({
    posterList: state.website?.config?.welcome_block_cards || [],
    texts: state.website?.config?.welcome_block_texts || {}
  }))

  return (
    <ContainerWithGradientBackground>
      <div className="welcome-block-wrapper" style={mobileCheck() ? { paddingLeft: 0, paddingRight: 0, paddingTop: 120 } : {}}>
        <div className="welcome-block-wrapper__header">
          <QDHeader className="font-weight-bold-zh font-weight-black-en" header={texts.header} color={QD_BLUE_1} style={{ fontSize: !mobileCheck() ? '3.4375rem' : '24px', marginBottom: 10, textTransform: 'uppercase' }} />
          <QDHeader className="font-weight-bold-zh font-weight-black-en" header={texts.sub_header} color={QD_RED_2} style={{ fontSize: !mobileCheck() ? '3.4375rem' : '24px', textTransform: 'uppercase' }} />
        </div>

        <div className="welcome-block-wrapper__description">
          <QDParagraph 
            className="font-weight-medium-zh font-weight-regular-en"
            paragraph={texts.paragraph} 
            style={{ color: QD_BLUE_1, fontSize: !mobileCheck() ? 20 : 16, textAlign: 'center' }}
          />
        </div>

        <div className="welcome-block-wrapper__poster-card">
          <Row style={{ justifyContent: 'center' }} gutter={[1, 0]}>
            {posterList.map((poster, idx) => (
              <Col key={`welcome-block-poster-${idx}`} span={mobileCheck() ? 12 : 6}>
                <PosterCard {...poster} posterIdx={idx} overlayDescriptionStyle={mobileCheck() ? { fontSize: 14 } : { fontSize: 16 } } />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </ContainerWithGradientBackground>
  )
}

export const FactBlock = () => {
  const carouselRef = useRef()
  const divRef = useRef()

  const [isInit, setIsInit] = useState(false)

  const { texts, figures } = useSelector(state => ({
    texts: state.website?.config?.fact_block_texts || {},
    figures: state.website?.config?.fact_block_figures || [],
  }))

  const { page_uuid } = useSelector(state => ({
    page_uuid: state.website?.config?.fact_block_texts.page_uuid
  }))

  const groupedFigures = useMemo(() => chunkMaxLength(figures || [], mobileCheck() ? 2 : 4), [figures])

  useEffect(() => {
    const el = divRef.current

    const handleScroll = () => {
      if (isElementInViewport(el)) {
        setIsInit(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <ContainerWithBackgroundImage imgUrl={texts.background_image_url}>
      <div className="fact-block-wrapper" ref={divRef} style={mobileCheck() ? { paddingLeft: 36, paddingRight: 36, paddingBottom: 64, gap: 0 } : {}}>
        <div className="fact-block-wrapper__body" style={mobileCheck() ? { gap: 24 } : {}}>
          <div className={classNames({
            "fact-block-wrapper__body__description": true, 
            "fact-block-wrapper__body__active": isInit || mobileCheck()
          })} >
            <QDHeader className="font-weight-bold-zh font-weight-black-en" header={texts.header} color="white" style={{ lineHeight: 1, textTransform: 'uppercase', fontSize: 48 }} />
            <QDParagraph 
              className="font-weight-regular-zh font-weight-regular-en"
              style={{ margin: '30px 0', fontSize: 16, lineHeight: '30px' }}
              paragraph={texts.paragraph} 
            />
          </div>
          <div className={classNames({
            "fact-carousel-wrapper": true,
            "fact-carousel-wrapper__active": isInit || mobileCheck(),
          })}>
            <QDCarouselDivider onNext={() => carouselRef.current?.next()} onPrev={() => carouselRef.current?.prev()} />
            <Carousel ref={carouselRef} className="fact-carousel-wrapper__body">
              {
                groupedFigures.map((items, idx) => (
                  <div key={`figure-group-${idx}`}>
                    <div className="fact-carousel-wrapper__item">
                      <div>
                        {
                          items.slice(0, 2).map((item, idx2) => (
                            <QDFactStats key={`figure-group-${idx}-item-${idx2}`} iconBackgroundColor={idx2 % 2 === 0 ? QD_RED_1 : 'white'} title={item.figure} descriptor={item.description}>
                              <img src={item.icon} alt="" width="100%" height="100%" style={{ objectFit: 'cover', borderRadius: '50%' }} />
                            </QDFactStats>
                          ))
                        }
                      </div>
                      {!mobileCheck() && (
                        <div>
                          {
                            items.slice(2, 4).map((item, idx2) => (
                              <QDFactStats key={`figure-group-${idx}-item-${idx2}`} iconBackgroundColor={idx2 % 2 === 0 ? QD_RED_1 : 'white'} title={item.figure} descriptor={item.description}>
                                <img src={item.icon} alt="" width="100%" height="100%" style={{ objectFit: 'cover', borderRadius: '50%' }} />
                              </QDFactStats>
                            ))
                          }
                        </div>
                      )}
                    </div>
                  </div>
                ))
              }


            </Carousel>
          </div>
        </div>
        <div className={classNames({
          "fact-block-wrapper__body__btn": true,
          "fact-block-wrapper__body__active": isInit || mobileCheck()
        })}>
          <QDButton page_uuid={page_uuid} borderColor='white' color='white' backgroundColor='transparent' overlayBackgroundColor={QD_RED_2} overlayColor="white" text={texts?.btn_text || "MORE FACTS"} style={{ padding: '17px 22px 17px 30px' }} />
        </div>

      </div>


    </ContainerWithBackgroundImage>
  )
}

export const EventBlock = () => {
  const navigate = useNavigate()

  const locale = useSelector(state => state.website?.locale)
  const newsList = useSelector(state => state.website?.newsList?.filter(news => news.display_on_home_page) || [])

  const [modalProps, setModalProps] = useState({
    visible: false,
    news_uuid: ''
  })

  return (
    <ContainerWithGradientBackground>
      <div className="event-block-wrapper" style={mobileCheck() ? { paddingTop: 120, paddingBottom: 36 } : {}}>
        <div className="event-block-wrapper__header">
          <QDHeader className="font-weight-bold-zh font-weight-black-en" header={locale === 'zh' ? '新闻与活动' : 'NEWS & EVENTS'} color={QD_BLUE_1} style={mobileCheck() ? { fontSize: 36 } : {}} />
        </div>
        <div className="event-block-wrapper__body">
        <FlexContainer flexDirection={'row'} gap={mobileCheck() ? '16px' : '8px'} style={mobileCheck() ? { margin: '0 -30px', padding: '0 30px', overflowX: 'auto' } : {}}>
            {newsList.slice(0, 3).map((news, idx) => (
              <NewsCard {...news} key={`news-${idx}`} newsIdx={idx} onClick={(news_uuid) => {
                setModalProps({ visible: true, news_uuid })
              }} style={mobileCheck() ? { animation: 'none' } : {}} />
            ))}
          </FlexContainer>
          
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
          <QDButton onClick={() => navigate('/news')} style={{ padding: '17px 30px', fontWeight: 900 }} borderColor='#13234B' backgroundColor='transparent' color="#13234B" overlayBackgroundColor={QD_RED_2} overlayColor="white" text={locale === 'zh' ? '更多新闻' : 'MORE NEWS'} />
        </div>
      </div>
      <Modal width={760} visible={modalProps.visible} footer={null} closeIcon={<CloseOutlined style={{ color: 'white' }} />} onCancel={() => setModalProps({ visible: false, news_uuid: '' })} bodyStyle={{ padding: 0 }}>
        <NewsEvent news_uuid={modalProps.news_uuid} showInModal={true} />
      </Modal>
    </ContainerWithGradientBackground>
  )
}

export const VideoBlock = () => {
  const texts = useSelector(state => state.website?.config?.video_block_texts)
  const [visible, setVisible] = useState(false)

  return (
    <ContainerWithBackgroundImage type="shadow" imgUrl={texts.poster_url}>
      <div className="video-block-wrapper">
        <div className="video-block-wrapper__descriptor">
          <QDHeader className="font-weight-bold-zh font-weight-black-en" header={texts.header} color="white" style={{ marginBottom: 24, fontSize: 48 }} />
          <QDParagraph className="font-weight-regular-zh font-weight-regular-en" paragraph={texts.paragraph} style={{ fontSize: 16, lineHeight: '30px', marginBottom: 24 }} />
          <QDButton onClick={() => {
            setVisible(true)
          }} style={{ padding: '17px 30px' }} borderColor='white' backgroundColor='transparent' color="white" overlayBackgroundColor={QD_RED_2} overlayColor="white" text={texts.btn_text} />
        </div>
      </div>

      <Modal destroyOnClose visible={visible} bodyStyle={{ padding: '12px 12px 8px' }} width={760} title={null} footer={null} onCancel={() => setVisible(false)}>
        <video controls src={texts?.video_url} width="100%" height="100%"></video>
      </Modal>
    </ContainerWithBackgroundImage>
  )
}

export const FooterBlock = () => {

  const locale = useSelector(state => state.website?.locale)
  const { schoolVirtualIdentities, popularLinks, certificateList } = useSelector(state => ({
    schoolVirtualIdentities: state.website?.config?.school_virtual_identities || {},
    popularLinks: state.website?.config?.footer_block_popular_links || [],
    certificateList: state.website?.config?.footer_block_certificates || []
  }))

  const [hoveredContactImg, setHoveredContactImg] = useState('') 

  const contactList = [
    { type: 'facebook', iconDarkUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/Facebook dark.png', iconUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/Facebook.png', href: 'https://www.facebook.com/QibaoDwight' },
    { type: 'youtube', iconDarkUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/YouTube dark.png', iconUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/YouTube.png', href: 'https://www.youtube.com/channel/UCvjnGAr7tgeSieoq1hXqkXw' },
    { type: 'wechat', iconDarkUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/WeChat dark.png', iconUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/Wechat.png' },
    { type: 'linkedin', iconDarkUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/Linkedin dark.png', iconUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/Linkedin.png', href: 'https://www.linkedin.cn/school/shanghai-qibao-dwight-high-school/mycompany/' },
    { type: 'instagram', iconDarkUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/instagram dark.png', iconUrl: 'https://tais.obs.cn-east-3.myhuaweicloud.com/public/instagram.png', href: 'https://www.instagram.com/shanghaiqibaodwight/' },
  ]

  return (
    <ContainerWithGradientBackground>
      <div className="footer-block-wrapper" style={mobileCheck() ? { paddingLeft: 36, paddingRight: 36 } : {}}>
        <div className="footer-block-wrapper__body">

          <FlexContainer gap={mobileCheck() ? '24px' : '72px'} flexDirection={mobileCheck() ? 'column' : 'row'}>
            <div>
              <img src={schoolVirtualIdentities.logo_horizontal} alt="horizontal-logo" className="footer-block-wrapper__logo" />

              <QDHeader className="font-weight-bold-zh font-weight-bold-en" header={locale === 'zh' ? "地址" : "ADDRESS"} color={QD_BLUE_1} style={{ fontSize: 18 }} />
              <QDParagraph paragraph={locale === 'zh' ? "上海市闵行区虹莘路3233号" : "No. 3233, Hongxin Road, Minhang District, Shanghai, China"} style={{ color: 'black', fontWeight: 400, marginTop: 12 }} />
              <QDHeader className="font-weight-bold-zh font-weight-bold-en" header={locale === 'zh' ? "招生" : "ADMISSIONS"} color={QD_BLUE_1} style={{ fontSize: 18 }} />
              
              <FlexContainer flexDirection="column" gap="4px">
                <QDParagraph paragraph={<a href="mailto:admissions@qibaodwight.org">admissions@qibaodwight.org</a>}  style={{ color: 'black', fontWeight: 400, marginTop: 12, marginBottom: 0 }} />
                <QDParagraph paragraph={`+86 (021) 34909928, 54855649`}  style={{ color: 'black', fontWeight: 400, margin: 0 }} />
                <QDParagraph paragraph={`+86 (021) 34909939*8105`}  style={{ color: 'black', fontWeight: 400, marginTop: 0 }} />
              </FlexContainer>

              <QDHeader className="font-weight-bold-zh font-weight-bold-en" header={locale === 'zh' ? "公共事务" : "COMMUNICATION"} color={QD_BLUE_1} style={{ fontSize: 18 }} />
              <QDParagraph paragraph={`+86 (021) 34908092`} style={{ color: 'black', fontWeight: 400, marginTop: 12, marginBottom: 0 }} />
            </div>

            {mobileCheck() ? <Divider style={{ margin: 0 }} /> : <Divider type="vertical" style={{ height: 150, marginTop: 96 }} />}
            <div style={{ marginTop: mobileCheck() ? 0 : 96 }}>
              <QDHeader className="font-weight-bold-zh font-weight-bold-en" header={locale === 'zh' ? "友情链接" : "POPULAR LINKS"} color={QD_BLUE_1} style={{ fontSize: 18 }} />
              <FlexContainer gap="4px" style={{ marginTop: 12 }} flexDirection="column">
                {popularLinks.map(link => (
                  <div key={link.link} className="footer-block-wrapper__popular-link" onClick={() => window.open(link.redirect_url)}>{link.label}</div>
                ))}
              </FlexContainer>
            </div>

            {mobileCheck() ? <Divider style={{ margin: 0 }} /> : <Divider type="vertical" style={{ height: 150, marginTop: 96 }} />}
            <div style={{ marginTop: mobileCheck() ? 0 : 96 }}>
              <QDHeader className="font-weight-bold-zh font-weight-bold-en" header={locale === 'zh' ? "联系我们" : "CONNECT WITH US"} color={QD_BLUE_1} style={{ fontSize: 18 }} />
              <FlexContainer gap="10px" style={{ marginTop: 12 }}>
                {contactList.map(contact => {

                  if (contact.type === 'wechat') {
                    return (
                      <span style={{ cursor: 'pointer' }} onClick={() => {
                        Modal.info({
                          icon: null,
                          title: <FlexContainer justifyContent="center" style={{ textTransform: 'uppercase', fontSize: 20 }}>{locale === 'zh' ? "扫码关注官方微信公众号" : "Scan QR Code and subscribe to our official WeChat account"}</FlexContainer>,
                          className: "wechat-qrcode-modal",
                          maskClosable: true,
                          content: (
                            <>
                              <img width="100%" alt="qdhs-qrcode" src="https://tais.obs.cn-east-3.myhuaweicloud.com/public/qr.jpeg" />
                            </>
                          )
                        })
                      }}>
                        <img src={hoveredContactImg === contact.type ? contact.iconDarkUrl : contact.iconUrl} width="50px" alt={contact.type} onMouseEnter={() => setHoveredContactImg(contact.type)} onMouseLeave={() => setHoveredContactImg('')} />
                      </span>
                    )
                  }
                                
                  return (
                    <a key={contact.type} target="_blank" href={contact.href} rel="noreferrer">
                      <img src={hoveredContactImg === contact.type ? contact.iconDarkUrl : contact.iconUrl} width="50px" alt={contact.type} onMouseEnter={() => setHoveredContactImg(contact.type)} onMouseLeave={() => setHoveredContactImg('')} />
                    </a>
                  )
                })}
              </FlexContainer>
            </div>
          </FlexContainer>
        </div>
        <Divider />
        <div className="footer-block-wrapper__certificate">
          {certificateList.map((certificate, idx) => (
            <img key={certificate.image_url} src={certificate.image_url} alt={`certificate-${idx+1}`} onClick={() => {
              certificate.redirect_url && window.open(certificate.redirect_url)
            }} />
          ))}
        </div>
        <div className="footer-block-wrapper__copyright">
          <span>Copyright © 2023-2028 上海七宝德怀特高级中学 版权所有 <a href={`https://beian.miit.gov.cn/#/Integrated/index`} target="_blank" rel="noreferrer">沪ICP备17030003号</a></span>
          <span><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=17030003" target="_blank" rel="noreferrer"><VerifiedOutlined style={{ fontSize: 16 }} /></a> 公安备案 沪公网安备 31011202007518号</span>                
        </div>
      </div>


    </ContainerWithGradientBackground>
  )
}