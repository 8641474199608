import { QD_RED_2 } from "../../constants"
import QDHeader from '../QDHeader'
import QDParagraph from "../QDParagraph"
import './index.less'

const QDFactStats = ({
  iconBackgroundColor,
  children,
  title,
  descriptor
}) => {
  return (
    <div className="qd-fact-stats-wrapper">
      <div className="qd-fact-stats-wrapper__icon" style={{ backgroundColor: iconBackgroundColor }}>
        {children}
      </div>
      <div style={{ paddingTop: 16 }}>
        <QDHeader className="font-weight-bold-zh font-weight-black-en" header={title} color="white" style={{ lineHeight: 1, marginBottom: -6, fontSize: '2.5rem' }} />
        <QDParagraph className="font-weight-medium-zh font-weight-medium-en" paragraph={descriptor} style={{ fontSize: '1.0625rem' }} />
      </div>
    </div>
  )
}

export default QDFactStats